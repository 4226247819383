import Error404 from '@tech_hexeko/base-front/src/components/Errors/404.vue';

function propagatePermissions(routes) {
    // Apply children permissions on parents routes
    return routes.map((route) => {
        if (route.children && route.children.length > 0) {
            // if we have a level 3 menu
            // route.children = propagatePermissions(route.children);
            const childPermissions = route.children.flatMap((child) => child.meta?.permissions || []);
            route.meta = route.meta || {};
            route.meta.permissions = Array.from(new Set([...(route.meta.permissions || []), ...childPermissions]));
        }

        return route;
    });
}

let routes = [
    {
        path: '/',
        component: require('./components/Dashboard/index').default,
        name: 'dashboard',
    },
    {
        path: '/users',
        component: require('./components/layout/Tabs/Users').default,
        meta: { label: 'Users', permissions: ['showUsers', 'manageUsers'] },
        children: [
            {
                path: '',
                component: require('./components/Users').default,
                name: 'users',
                meta: { label: 'Users', icon: 'users', permissions: ['showUsers', 'manageUsers'] },
            },
            {
                path: '/groups',
                component: require('./components/Groups/index').default,
                name: 'groups',
                meta: {
                    label: 'Groups',
                    icon: 'layer-group',
                    permissions: ['showGroups', 'manageGroups'],
                    parent: '/users',
                },
            },
            {
                path: '/wallets',
                component: require('./components/Wallets').default,
                name: 'wallets',
                meta: {
                    label: 'Wallets',
                    icon: 'wallet',
                    permissions: ['showWallets', 'manageWallets'],
                    parent: '/users',
                },
            },
        ],
    },
    {
        path: '/cards',
        component: require('./components/layout/Tabs/Cards').default,
        meta: { label: 'Cards', permissions: ['showCards', 'manageCards'] },
        children: [
            {
                path: '',
                component: require('./components/Cards').default,
                name: 'cards',
                meta: { label: 'Cards', icon: 'credit-card', permissions: ['showCards', 'manageCards'] },
            },
            {
                path: 'authorizations',
                component: require('./components/CardAuthorizations').default,
                name: 'card-authorizations',
                meta: { label: 'Authorizations', icon: 'key', permissions: ['showCards', 'manageCards'] },
            },
            {
                path: 'transactions',
                component: require('./components/CardTransactions').default,
                name: 'card-transactions',
                meta: { label: 'Transactions', icon: 'exchange-alt', permissions: ['showCards', 'manageCards'] },
            },
        ],
    },
    {
        path: '/expenses',
        component: require('./components/layout/Tabs/Expenses').default,
        meta: { label: 'Expenses', permissions: ['showExpenses', 'manageExpenses'] },
        children: [
            {
                path: '',
                component: require('./components/Expenses').default,
                name: 'expenses',
                meta: {
                    label: 'Expenses',
                    icon: 'file-invoice-dollar',
                    permissions: ['showExpenses', 'manageExpenses'],
                },
            },
            {
                path: 'requests',
                component: require('./components/ExpenseRequests').default,
                name: 'expense-requests',
                meta: {
                    label: 'Requests',
                    icon: 'file-invoice',
                    permissions: ['showExpenseRequests', 'manageExpenseRequests'],
                },
            },
            {
                path: 'allowances',
                component: require('./components/Allowances').default,
                name: 'allowances',
                meta: { label: 'Allowances', icon: 'coins', permissions: ['showAllowances', 'manageAllowances'] },
            },
            {
                path: 'payment-intents',
                component: require('./components/PaymentIntents').default,
                name: 'payment-intents',
                meta: {
                    label: 'Intents',
                    icon: 'file-alt',
                    permissions: ['showPaymentIntents', 'managePaymentIntents'],
                },
            },
            {
                path: 'payment-transactions',
                component: require('./components/PaymentTransactions').default,
                name: 'payment-transactions',
                meta: {
                    label: 'Transactions',
                    icon: 'file-invoice-dollar',
                    permissions: ['showPaymentTransactions', 'managePaymentTransactions'],
                },
            },
        ],
    },
    {
        path: '/bookings',
        component: require('./components/layout/Tabs/Bookings').default,
        meta: { label: 'Bookings', permissions: ['showBookings', 'manageBookings'] },
        children: [
            {
                path: '',
                component: require('./components/Bookings').default,
                name: 'bookings',
                meta: { label: 'Bookings', icon: 'calendar-check', permissions: ['showBookings', 'manageBookings'] },
            },
            {
                path: 'requests',
                component: require('./components/BookingRequests').default,
                name: 'booking-requests',
                meta: {
                    label: 'Requests',
                    icon: 'file-alt',
                    permissions: ['showBookingRequests', 'manageBookingRequests'],
                },
            },
            {
                path: 'vouchers',
                component: require('./components/VoucherLists/index').default,
                name: 'vouchers',
                meta: { label: 'Vouchers', icon: 'ticket-alt', permissions: ['showVouchers', 'manageVouchers'] },
            },
        ],
    },
    {
        path: '/partners',
        component: require('./components/layout/Tabs/Partners').default,
        meta: { label: 'Partners', permissions: ['showPartners', 'managePartners'] },
        children: [
            {
                path: '',
                component: require('./components/Partners').default,
                name: 'partners',
                meta: { label: 'Partners', icon: 'users', permissions: ['showPartners', 'managePartners'] },
            },
            {
                path: 'types',
                component: require('./components/Types').default,
                name: 'types',
                meta: { label: 'Types', icon: 'tags', permissions: ['showTypes', 'manageTypes'] },
            },
        ],
    },
    {
        path: '/payouts',
        component: require('./components/layout/Tabs/Payouts').default,
        meta: { label: 'Payouts', permissions: ['showPayouts', 'managePayouts'] },
        children: [
            {
                path: '',
                component: require('./components/Payouts/index').default,
                name: 'payouts',
                meta: { label: 'Payouts', icon: 'coins', permissions: ['showPayouts', 'managePayouts'] },
            },
            {
                path: 'requests',
                component: require('./components/PayoutRequests/index').default,
                name: 'payout-requests',
                meta: {
                    label: 'Payout requests',
                    icon: 'file-invoice-dollar',
                    permissions: ['showPayouts', 'managePayouts'],
                },
            },
        ],
    },
    {
        path: '/billing',
        name: 'billing',
        component: require('./components/layout/Tabs/Billing').default,
        meta: { label: 'Billing', permissions: ['viewAnyQuotes', 'manageQuotes'] },
        children: [
            {
                path: 'quotes',
                component: require('./components/Quotes/index').default,
                name: 'quotes',
                meta: { label: 'Quotes', icon: 'file-contract', permissions: ['viewAnyQuotes', 'manageQuotes'] },
            },
            {
                path: 'invoices',
                component: require('./components/Invoices/index').default,
                name: 'invoices',
                meta: { label: 'Invoices', icon: 'file-invoice', permissions: ['viewAnyInvoices', 'manageInvoices'] },
            },
            {
                path: 'credit-notes',
                component: require('./components/CreditNotes').default,
                name: 'credit-notes',
                meta: {
                    label: 'Credit notes',
                    icon: 'file-invoice',
                    permissions: ['viewAnyInvoices', 'manageInvoices'],
                },
            },
            {
                path: 'accounts',
                component: require('./components/BillingAccounts/index').default,
                name: 'accounts',
                meta: {
                    label: 'Billing accounts',
                    icon: 'building',
                    permissions: ['viewAnyBillingAccounts', 'manageBillingAccounts'],
                },
            },
            {
                path: 'products',
                component: require('./components/Products/index').default,
                name: 'products',
                meta: {
                    label: 'Products',
                    icon: 'building',
                    permissions: ['viewAnyProducts', 'manageProducts'],
                },
            },
        ],
    },
    {
        path: '/config',
        name: 'config',
        component: require('./components/Config/index').default,
        meta: { label: 'Configuration', permissions: ['showStores', 'manageStores'] },
        children: [
            {
                path: 'stores',
                component: require('./components/Stores/index').default,
                name: 'stores',
                meta: { label: 'Stores', icon: 'store', permissions: ['showStores', 'manageStores'] },
            },
            {
                path: 'roles',
                component: require('./components/Roles/index').default,
                name: 'roles',
                meta: { label: 'Roles', icon: 'user-shield', permissions: ['showPrivileges', 'managePrivileges'] },
            },
        ],
    },
    {
        path: '/index.html',
        redirect: { name: 'dashboard' },
    },
    {
        path: '*',
        name: '404',
        component: Error404,
        meta: { fullscreen: true, requiresAuth: false },
    },
];

routes = propagatePermissions(routes);
export default routes;
