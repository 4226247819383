export default [
    {
        fr: 'Veuillez ajouter votre ticket à votre demande de remboursement',
        nl: 'Voeg uw ticket toe aan uw aanvraag',
        en: 'Please add your ticket to your refund request',
    },
    {
        fr: 'Veuillez ajouter un ticket de caisse avec le détail de vos achats',
        nl: 'Voeg een ontvangstbewijs toe met details van uw aankopen',
        en: 'Please add a receipt with details of your purchases',
    },
    {
        fr: 'Votre demande ne rentre pas dans les conditions du chèque sport et culture',
        nl: 'Uw verzoek valt niet onder de voorwaarden van de sport en cultuur voucher',
        en: 'Your request is not eligible for the sport and culture voucher',
    },
    {
        fr: 'Vous avez déjà soumis cette demande de remboursement',
        nl: 'U heeft deze claim al ingediend',
        en: 'You have already submitted this refund request',
    },
    {
        fr: 'Veuillez ajouter un ticket valide reprenant les informations suivantes : prix, date, lieu de l’activité',
        nl: 'Voeg een geldig ticket toe met de volgende informatie: prijs, datum, plaats van de activiteit',
        en: 'Please add a valid ticket with the following information: price, date, location of the activity',
    },
    {
        fr: 'Votre ticket est illisible. Veuillez en introduire un nouveau',
        nl: 'Uw ticket is onleesbaar. Voer een nieuwe in',
        en: 'Your ticket is unreadable. Please insert a new one',
    },
    {
        fr: 'Cet/ces article(s) ne fait/ne font pas partie des accords Mobilité de votre entreprise',
        nl: 'Dit/deze artikel(en) maakt/maken geen deel uit van de Mobiliteitsovereenkomsten van uw bedrijf',
        en: "This/these article(s) is/are not part of your company's Mobility agreements",
    },
    {
        fr: 'Votre subvention «\xa0Forfait Mobilité Durable\xa0» est utilisable dans le cadre de vos déplacements domicile-lieu de travail',
        nl: 'Je “Forfait Mobilité Durable” subsidie kan gebruikt worden voor woon-werkverkeer',
        en: 'Your ‘Forfait Mobilité Durable’ subsidy can be used for journeys between home and work.',
    },
    {
        fr: 'Veuillez appliquer les 25% de réduction dont vous bénéficiez pour les produits de marque Décathlon ou 5% pour les produits des autres marques',
        nl: 'Voer de 25% korting in die je krijgt voor de producten van Decathlon of 5% voor de producten van andere merken',
        en: 'Please apply the 25% discount you receive for Decathlon products or 5% for other brand products',
    },
];
