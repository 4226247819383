<template>
    <Datatable
        name="Quotes"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
    >
        <template v-slot:filters>
            <Dropdown class="me-1" color="secondary" title="Filter by status" small>
                <li v-for="(filter, index) in filterCreationStatus" :key="'filter-' + index">
                    <a
                        href="#"
                        class="dropdown-item text-xs"
                        :class="isActiveFilter(filter.type) ? 'bg-primary text-white' : 'bg-white'"
                        @click.prevent="filterByStatus(filter.type)"
                        >{{ filter.text }}</a
                    >
                </li>
            </Dropdown>
            <Button
                class="me-1"
                @click="toggleFunded"
                :color="filterFunded ? 'success' : filterFunded === false ? 'danger' : 'secondary'"
                small
            >
                Funded
            </Button>
            <Button
                class="me-1"
                @click="toggleProcessed"
                :color="filterProcessed ? 'success' : filterProcessed === false ? 'danger' : 'secondary'"
                small
            >
                Processed
            </Button>
        </template>
        <template v-slot:thead>
            <tr>
                <th>Date</th>
                <th>Reference</th>
                <th>Types</th>
                <th>Billing account</th>
                <th>Total</th>
                <th>Status</th>
            </tr>
        </template>
        <tr v-for="(quote, index) in quotes" @click="open(quote)" :key="'quote-' + index">
            <td>{{ quote.created_at | formatDate }}</td>
            <td>{{ quote.reference }}</td>
            <td>
                <TypesIcon position="left" :types="quote.types" small />
            </td>
            <td>{{ quote.billing_account_name }}</td>
            <td>{{ quote.amount_total | currency }}</td>
            <td><QuoteStatus :quote="quote" /></td>
            <td class="text-right"><Icon icon="angle-right" /></td>
        </tr>
    </Datatable>
</template>

<script>
    import { Datatable, Dropdown, Boolean, Button } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import QuoteStatus from '@/components/Quotes/status'
    import TypesIcon from '@/components/Types/Icons'

    export default {
        name: 'Quotes',
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        data() {
            return {
                loading: true,
                pagination: {
                    current_page: 1,
                },
                quotes: [],
                filterCreationStatus: [
                    { type: 'draft', text: 'Draft' },
                    { type: 'open', text: 'Open' },
                    { type: 'accepted', text: 'Accepted' },
                    { type: 'canceled', text: 'Canceled' },
                ],
                filterFunded: null,
                filterProcessed: null,
                selectedFilter: null,
                search: null,
            }
        },
        components: {
            QuoteStatus,
            Datatable,
            Dropdown,
            Button,
            TypesIcon,
        },
        mixins: [Modals],
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData(search) {
                this.search = search
                this.loading = true
                this.$api.billing.quotes
                    .get(
                        this.mergeParams({
                            search: this.search,
                            page: this.pagination.current_page,
                            order_by: 'date',
                            sort_by: 'desc',
                            processed:
                                this.filterProcessed === true
                                    ? 1
                                    : this.filterProcessed === false
                                    ? 0
                                    : undefined,
                            funded: this.filterFunded === true
                                    ? 1
                                    : this.filterFunded === false
                                    ? 0
                                    : undefined,
                            finalized: this.selectedFilter === 'draft' ? 0 : undefined,
                            accepted: this.selectedFilter === 'accepted' ? 1 : this.selectedFilter === 'open' ? 0 : undefined,
                            canceled: this.selectedFilter === 'canceled' ? 0 : undefined,
                            isCancellable: true,
                        })
                    )
                    .then((response) => {
                        this.quotes = response.data
                        this.pagination = response.meta.pagination
                        this.loading = false
                    })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            open(quote) {
                let panel = this.openPanel('QuotePanel', quote.id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData(this.search)
                    }
                })
            },
            toggleFunded() {
                if (this.filterFunded === null) this.filterFunded = true
                else if (this.filterFunded === true) this.filterFunded = false
                else this.filterFunded = null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            toggleProcessed() {
                if (this.filterProcessed === null) this.filterProcessed = true
                else if (this.filterProcessed === true) this.filterProcessed = false
                else this.filterProcessed = null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            isActiveFilter(type) {
                return this.selectedFilter == type
            },
            filterByStatus(type) {
                this.selectedFilter = this.selectedFilter == type ? null : type
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
        },
    }
</script>
