<template>
    <FormRoot v-if="group">
        <!-- Note -->
        <Alert class="mb-3" color="info">
            This action will extend the validity of all wallet transactions of the group
            <strong>{{ group.name }}</strong> that match the data below. Be careful!
        </Alert>

        <!-- Type -->
        <Field
            :form.sync="form"
            type="select"
            optionsKeyBy="technical_name"
            optionsLabel="label"
            name="technical_name"
            placeholder="Type"
            :options="typesOptions"
            hint="Type of wallet"
        />

        <!-- Expired date -->
        <Field
            :form.sync="form"
            type="date"
            :max="dateToday"
            name="expired_date"
            placeholder="Expired date"
            hint="The expired date of wallet transactions to match"
        />

        <!-- New expiration date -->
        <Field
            :form.sync="form"
            type="date"
            :min="dateTomorrow"
            name="new_expiration_date"
            placeholder="Expired date"
            hint="The new expiration date to apply"
        />

        <!-- Actions -->
        <FormActions>
            <Button
                is="confirm"
                type="submit"
                @confirm="onSubmit()"
                class="float-right"
                :disabled="disableSubmit"
                color="primary"
                icon="arrows-alt-h"
                >Extend</Button
            >
        </FormActions>
    </FormRoot>
    <Alert v-else color="warning">No group selected</Alert>
</template>

<script>
    import { Alert, Button, Field, FormActions, FormRoot } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    import moment from 'moment'

    export default {
        name: 'ExtendWalletTransactions',
        mixins: [Forms],
        components: {
            Alert,
            Button,
            Field,
            FormRoot,
            FormActions,
        },
        props: {
            group: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                dateToday: moment().format('YYYY-MM-DD'),
                dateTomorrow: moment().add(1, 'days').format('YYYY-MM-DD'),
                fields: {
                    technical_name: null,
                    expired_date: null,
                    new_expiration_date: null,
                },
                messageSuccess: 'Corresponding wallets transactions have been extended',
                types: [],
            }
        },
        computed: {
            disableSubmit() {
                return (
                    !this.group?.id ||
                    !this.form.technical_name ||
                    !this.form.expired_date ||
                    !this.form.new_expiration_date ||
                    !this.form.submittable ||
                    this.form.errors.any()
                )
            },
            typesOptions() {
                const options = this.types?.map((type) => ({
                    technical_name: type.technical_name,
                    label: type.name.en,
                }))
                return options
            },
        },
        methods: {
            fetchTypes() {
                this.$api.types.get({ only_platform_visible: 1 }).then((types) => {
                    this.types = types.data
                })
            },
            submit() {
                return this.$api.groups.extendCredits(this.group.id, this.$data.form.data())
            },
        },
        mounted() {
            this.fetchTypes()
        },
        watch: {
            'form.expired_date'(newDate) {
                if (newDate && !moment(newDate).isSameOrBefore(this.dateToday)) {
                    this.form.errors.add('expired_date', "The expired date must be on or before today's date.")
                } else {
                    this.form.errors.clear('expired_date')
                }
            },
            'form.new_expiration_date'(newDate) {
                if (newDate && !moment(newDate).isSameOrAfter(this.dateTomorrow)) {
                    this.form.errors.add('new_expiration_date', "The new expiry date must be after today's date.")
                } else {
                    this.form.errors.clear('new_expiration_date')
                }
            },
        },
    }
</script>
