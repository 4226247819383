<template>
    <Panel title="Payment Intent" :loading="loading" @closePanel="closePanel">
        <Tabs v-if="!loading">
            <!-- Details -->
            <Tab name="Payment Intent" :selected="true">
                <div class="mb-2">
                    <PaymentIntentStatus :status="intent.status" />
                </div>
                <Sheet name="Details">
                    <Row name="User" v-if="intent.payer">
                        <a href="#" @click.prevent="openUser(intent.payer)" class="text-decoration-none"
                            ><Icon icon="user" /> {{ intent.payer.name }}</a
                        >
                    </Row>
                    <Row name="Payable" v-if="intent.payable">
                        <Payable :type="intent.payable_type" :payable="intent.payable" clickable />
                    </Row>
                    <Row name="Payment methods">
                        <SelectedPaymentMethod
                            class="ms-1"
                            v-for="(method, index) in intent.selected_payment_methods.payment_methods"
                            :paymentMethod="method"
                            :key="index"
                            :smallIcons="true"
                        />
                    </Row>
                    <Row name="Amount">
                        {{ intent.amount | currency }}
                    </Row>
                    <Row name="Transaction at">{{ intent.transaction_at | formatDate }}</Row>
                    <Row name="Created at">{{ intent.created_at | formatDate }}</Row>
                    <Row name="Updated at">{{ intent.updated_at | formatDate }}</Row>
                </Sheet>
                <ID name="Hexeko" :value="intent.id" />
            </Tab>

            <!-- Transactions -->
            <Tab
                name="Transactions"
                v-if="$store.getters.userHasAnyPermission(['showPaymentTransactions', 'managePaymentTransactions'])"
            >
                <Transactions :params="{ payment_intent: intent.id }"></Transactions>
            </Tab>

            <!-- History -->
            <Tab name="History">
                <History :statuses="intent.statuses" v-slot:default="slotProps">
                    <PaymentIntentStatus :status="slotProps.status" />
                </History>
            </Tab>
        </Tabs>
        <template v-slot:actions v-if="$store.getters.userHasPermission('managePaymentIntents') && intent">
            <!-- Actions -->
            <Dropdown small title="Actions" position="right">
                <DropdownItem confirm @confirm="retry" icon="undo">Retry to debit</DropdownItem>
                <DropdownItem confirm @confirm="forceRetry" icon="undo">Force retry to debit</DropdownItem>
                <DropdownItem @click="openModal('addPaymentTransaction')" icon="plus"
                    >Add payment transaction</DropdownItem
                >
                <!--DropdownItem confirm @confirm="refund" icon="undo">Refund</DropdownItem-->
            </Dropdown>

            <!-- Update activity -->
            <Modal
                v-if="isModalOpen('addPaymentTransaction')"
                @close="closeModal('addPaymentTransaction')"
                title="Add a payment transaction"
            >
                <addPaymentTransaction :id="intent.id" @submitted="paymentTransactionSubmited" />
            </Modal>
        </template>
    </Panel>
</template>

<script>
    import { Tabs, Tab, Panel, Sheet, Row, Dropdown, DropdownItem, ID, Modal } from '@tech_hexeko/design-system'
    import PaymentIntentStatus from '@/components/PaymentIntents/status'
    import SelectedPaymentMethod from '../layout/SelectedPaymentMethod'
    import Transactions from '@/components/PaymentTransactions'
    import Payable from '@/components/PaymentIntents/Payable'
    import History from '@/components/layout/History'
    import addPaymentTransaction from './addPaymentTransaction'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import { formatBoolean } from '@/utils/query'

    export default {
        name: 'PaymentIntentPanel',
        props: {
            id: String,
        },
        components: {
            addPaymentTransaction,
            PaymentIntentStatus,
            Panel,
            Sheet,
            Row,
            Tabs,
            Tab,
            Dropdown,
            DropdownItem,
            ID,
            SelectedPaymentMethod,
            Transactions,
            History,
            Payable,
            Modal,
        },
        data() {
            return {
                loading: true,
                intent: null,
            }
        },
        mixins: [Modals],
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData() {
                this.loading = true
                return this.$api.paymentIntents
                    .show(this.id, {
                        includes: ['payer', 'payable', 'payment_transactions', 'selected_payment_methods', 'statuses'],
                    })
                    .then((response) => {
                        this.intent = response
                        this.loading = false
                    })
            },
            openUser(user) {
                this.openPanel('userPanel', user.id)
            },
            closePanel(refresh) {
                this.$emit('closePanel', {
                    refresh: refresh,
                })
            },
            retry() {
                this.$api.paymentIntents.retry(this.id).then(() => {
                    this.fetchData()
                })
            },
            forceRetry() {
                this.$api.paymentIntents.retry(this.id, { forced: formatBoolean(true) }).then(() => {
                    this.fetchData()
                })
            },
            refund() {
                this.$api.paymentIntents.refund(this.id).then(() => {
                    this.fetchData()
                })
            },
            paymentTransactionSubmited() {
                this.closeModal('addPaymentTransaction')
                this.fetchData()
            },
        },
    }
</script>
