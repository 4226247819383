<template>
    <div :class="{ 'container-fluid': !fullscreen }">
        <LoaderFullscreen v-if="loading"></LoaderFullscreen>
        <template v-else>
            <header v-if="!fullscreen" class="header-main">
                <Navbar class="py-3"></Navbar>
            </header>
            <main>
                <router-view></router-view>
                <slideout-panel></slideout-panel>
            </main>
            <Footer class="my-4" v-if="!fullscreen"></Footer>
        </template>
    </div>
</template>

<script>
    import Navbar from '@/components/layout/Navbar'
    import Footer from '@/components/layout/Footer'
    import { LoaderFullscreen } from '@tech_hexeko/design-system'

    export default {
        name: 'Admin',

        components: {
            LoaderFullscreen,
            Navbar,
            Footer,
        },

        data() {
            return {
                loading: true,
            }
        },

        mounted() {
            this.fetchLayoutData()
        },

        methods: {
            fetchLayoutData() {
                this.loading = true
                // Get stores
                this.$api.stores.get().then((response) => {
                    this.$store.commit('setStores', response.data)
                    this.loading = false
                })
            },
        },

        computed: {
            fullscreen() {
                return this.$route.meta.fullscreen ? true : false
            },
        },
    }
</script>
