<template>
    <Panel title="Credit note" :loading="loading" @closePanel="$emit('closePanel', { refresh: false })">
        <!-- Tabs -->
        <Tabs v-if="creditNote">
            <!-- Details -->
            <Tab name="Credit note" :selected="true">
                <Sheet name="Details" class="mb-2">
                    <Row name="Date">{{ creditNote.created_at | formatDate }}</Row>
                    <Row name="Amount">{{ creditNote.amount | currency }}</Row>
                    <Row v-if="creditNote.invoice_id" name="Invoice">
                        <a href="#" @click.prevent="openInvoice(creditNote.invoice_id)">{{ creditNote.invoice_id }}</a>
                    </Row>
                    <Row v-if="creditNote.reason" name="Reason">{{ creditNote.reason }}</Row>
                    <Row v-if="creditNote.memo" name="Memo">{{ creditNote.memo }}</Row>
                    <Row v-if="creditNote.metadata && Object.keys(creditNote.metadata).length > 0" name="Metadata">
                        <code class="d-block text-muted">
                            <template v-for="(metadata, key) in creditNote.metadata">
                                {{ key }}: {{ metadata }}<br />
                            </template>
                        </code>
                    </Row>
                </Sheet>

                <Sheet name="Billing account">
                    <Row name="Name"
                        ><a href="#" @click.prevent="openBillingAccount(creditNote.billing_account)">{{
                            creditNote.billing_account.name
                        }}</a></Row
                    >
                    <Row name="City"
                        >{{ creditNote.billing_account.city }} {{ creditNote.billing_account.country_iso }}</Row
                    >
                </Sheet>

                <ID name="Hexeko" :value="creditNote.id" />
            </Tab>

            <!-- Lines -->
            <Tab v-if="creditNote.lines && creditNote.lines.data" name="Lines">
                <Table striped borderless>
                    <template v-slot:thead>
                        <tr>
                            <th width="60%">Product</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Amount</th>
                        </tr>
                    </template>
                    <LineRow
                        v-for="(line, index) in creditNote.lines.data"
                        :quantity="line.quantity"
                        :title="line.description"
                        :amount="line.amount"
                        :icon="false"
                        :amount_unit="line.unit_amount"
                        :key="'line-' + index"
                    />
                </Table>
            </Tab>
        </Tabs>

        <template
            v-if="$store.getters.userHasAnyPermission(['manageInvoices', 'viewAnyBillingAccounts'])"
            v-slot:actions
        >
            <!-- Actions -->
            <Button
                v-if="!!id"
                @click="downloadCreditNote"
                color="secondary"
                icon="download"
                :loading="loadingDownload"
                small
                >Download</Button
            >
        </template>
    </Panel>
</template>

<script>
    import { Alerts, Button, ID, Panel, Row, Sheet, Tab, Table, Tabs } from '@tech_hexeko/design-system'
    import LineRow from '@/components/layout/LineRow'

    export default {
        name: 'CreditNotePanel',
        components: {
            Button,
            ID,
            Panel,
            Row,
            Sheet,
            Tab,
            Table,
            Tabs,
            LineRow,
        },
        data() {
            return {
                creditNote: null,
                loading: true,
                loadingDownload: false,
            }
        },
        props: {
            id: {
                type: String,
                required: true,
            },
        },
        methods: {
            fetchData() {
                if (!this.id) {
                    Alerts.notificationError('No credit note ID was provided')
                    return
                }
                this.loading = true

                this.$api.billing.creditNotes
                    .show(this.id, { includes: ['billing_account'] })
                    .then((response) => {
                        this.creditNote = response
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response && error.response.data
                                ? error.response.data.message
                                : 'An error occured while loading the credit note'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            openBillingAccount(account) {
                this.openPanel('BillingAccountPanel', account.id)
            },
            openInvoice(invoiceId) {
                this.openPanel('InvoicePanel', invoiceId)
            },
            downloadCreditNote() {
                this.loadingDownload = true
                this.$api.billing.creditNotes
                    .download(this.id)
                    .then((response) => {
                        window.open(response.file_url, '_blank')
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response && error.response.data
                                ? error.response.data.message
                                : 'An error occured while trying to download the credit note'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        setTimeout(() => (this.loadingDownload = false), 4000)
                    })
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
