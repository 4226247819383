<template>
    <Panel title="Card authorization" :loading="loading" @closePanel="$emit('closePanel', { refresh: hasSubmitted })">
        <Tabs v-if="authorization">
            <!-- Card authorization -->
            <Tab name="Card authorization" :selected="true">
                <div class="mb-2">
                    <CardAuthorizationStatus v-if="authorization.status" :status="authorization.status" />
                    <Boolean
                        value="!!authorization.partner"
                        textOn="Partner identified"
                        textOff="Partner unknown"
                        class="ms-1"
                    />
                    <Badge v-if="authorization.fraudulent" color="danger" icon="exclamation-circle" class="ms-1"
                        >Reported as fraudulent</Badge
                    >
                </div>

                <Alert v-if="authorized" color="success" icon="check-circle">
                    This transaction has been authorized
                </Alert>
                <Alert v-else icon="stop-circle">This transaction has not been authorized</Alert>
                <Alert
                    v-if="authorization.status && authorization.status.reason"
                    icon="info-circle"
                    color="warning"
                    class="mt-2"
                >
                    <Reason :slug="authorization.status.reason" />
                </Alert>

                <Sheet name="Details" class="mb-2">
                    <Row name="Date">{{ authorization.transaction_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</Row>
                    <Row name="Amount">{{ authorization.amount | currency }}</Row>
                    <Row name="User" v-if="authorization.user">
                        <div>
                            <a href="#" @click.prevent="openUser(authorization.user)">{{ authorization.user.name }}</a>
                        </div>
                    </Row>
                    <Row name="Card" v-if="authorization.card">
                        <div>
                            <a href="#" @click.prevent="openCard(authorization.card)"
                                >•••• {{ authorization.card.last_four }} ({{ authorization.card.name_on_card }})</a
                            >
                        </div>
                    </Row>
                    <Row name="Method" v-if="authorization.authorization_method">{{
                        authorization.authorization_method
                    }}</Row>
                    <Row name="Made through" v-if="authorization.digital_wallet">{{ digitalWallet }}</Row>
                    <Row name="Requested at">{{ authorization.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</Row>
                </Sheet>
                <Sheet name="Merchant">
                    <Row name="ID">{{ authorization.merchant.id }}</Row>
                    <Row name="Category">{{ authorization.merchant.category }}</Row>
                    <Row name="Name">{{ authorization.merchant.name }}</Row>
                    <Row name="Address"
                        >{{ authorization.merchant.address.city }} {{ authorization.merchant.address.country }}</Row
                    >
                    <Row name="Identified partner" v-if="authorization.partner">
                        <div>
                            <a href="#" @click.prevent="openPartner(authorization.partner)">{{
                                authorization.partner.name[$store.state.lang.displayLanguage]
                            }}</a>
                        </div>
                    </Row>
                </Sheet>
                <ID name="Hexeko" :value="authorization.id" />
                <ID
                    name="Stripe"
                    :url="`https://dashboard.stripe.com/${authorization.card.issuer.external_id.stripe}/issuing/authorizations/${authorization.external_id}`"
                    :value="authorization.external_id"
                    class="ms-3"
                />
            </Tab>
            <!-- Transactions -->
            <Tab name="Transactions">
                <div class="mb-2">
                    <Boolean color="success" value="captured" textOn="Captured" textOff="Not captured" />
                </div>

                <Alert color="warning" v-if="!captured" icon="clock">
                    This authorization doesn't have card transactions
                </Alert>
                <Alert color="success" icon="check-circle" v-else>This authorization has card transactions</Alert>

                <!-- Amounts -->
                <Sheet class="mb-4">
                    <Row name="Types">
                        <div class="row" v-for="type in allowedTypes" :key="type.id">
                            <div class="col-3">
                                <Badge small class="me-1" color="secondary"
                                    >by <span class="text-capitalize">{{ type.source }}</span></Badge
                                >
                            </div>
                            <div class="col-9">
                                <span>{{ type.name[$store.state.lang.displayLanguage] }}</span>
                            </div>
                        </div>
                    </Row>
                    <Row name="Amount of authorization">{{ authorization.amount | currency }}</Row>
                    <Row name="Sum of card transactions">{{ authorization.card_transactions_amount | currency }}</Row>
                    <Row name="Overflow amount" v-if="authorization.overflow_amount">{{
                        authorization.overflow_amount | currency
                    }}</Row>
                </Sheet>

                <!-- Selected payment methods -->
                <div class="text-muted text-xxs mb-1">Selected payment methods</div>
                <Table striped borderless hover class="mb-4">
                    <tr v-for="(method, index) in allowedPaymentMethods" :key="index">
                        <td><SelectedPaymentMethod :paymentMethod="method" :key="index" /></td>
                    </tr>
                </Table>

                <!-- Card transactions -->
                <div class="text-muted text-xxs mb-1">Card transactions</div>
                <Table striped borderless hover class="mb-4">
                    <template v-slot:thead>
                        <tr>
                            <th>Card transaction</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Status</th>
                        </tr>
                    </template>
                    <tr
                        v-for="(transaction, index) in authorization.card_transactions"
                        :key="index"
                        @click.prevent="openTransaction(transaction)"
                    >
                        <td>
                            <code>{{ transaction.id }}</code>
                        </td>
                        <td>{{ transaction.transaction_at | formatDate }}</td>
                        <td>{{ transaction.amount | currency }}</td>
                        <td><CardTransactionStatus :status="transaction.status" /></td>
                    </tr>
                </Table>
            </Tab>

            <!-- History -->
            <Tab name="History">
                <History :statuses="authorization.statuses" v-slot:default="slotProps">
                    <CardAuthorizationStatus :status="slotProps.status" />
                </History>
            </Tab>
        </Tabs>

        <template v-slot:actions v-if="$store.getters.userHasPermission('manageCards') && authorization">
            <!-- Partner -->
            <Button small @click="openPartner(authorization.partner)" icon="handshake" v-if="authorization.partner">{{
                authorization.partner.name[$store.state.lang.displayLanguage]
            }}</Button>
            <Button small @click="openModal('identifyMerchant')" icon="handshake" v-else>Identify merchant</Button>

            <!-- Actions -->
            <Dropdown v-if="authorization" class="ms-1" title="Actions" position="right" small>
                <DropdownItem
                    confirm
                    @confirm="reportAsFraudulent"
                    text="This card authorization will be reported as fraudulent"
                    icon="exclamation-circle"
                    :disabled="authorization.fraudulent"
                    >Report as fraudulent</DropdownItem
                >
                <DropdownItem @click="openModal('identifyType')" icon="plus">Identify type(s)</DropdownItem>
            </Dropdown>
            <Modal
                v-if="isModalOpen('identifyMerchant')"
                @close="closeModal('identifyMerchant')"
                title="Identify merchant"
            >
                <IdentifyMerchant :transaction="authorization.id" @submitted="merchantIdentified" />
            </Modal>
            <Modal
                v-if="isModalOpen('identifyType')"
                @close="closeModal('identifyType')"
                title="Identify the corresponding type(s)"
            >
                <IdentifyType
                    :authorizationId="authorization.id"
                    @submitted="typesAdded"
                    :allowedTypes="allowedTypes"
                />
            </Modal>
        </template>
    </Panel>
</template>

<script>
    import {
        Alert,
        Alerts,
        Badge,
        Boolean,
        Button,
        Dropdown,
        DropdownItem,
        ID,
        Modal,
        Panel,
        Row,
        Sheet,
        Tab,
        Table,
        Tabs,
    } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import IdentifyMerchant from './IdentifyMerchant'
    import IdentifyType from './IdentifyType'
    import CardAuthorizationStatus from './status'
    import axios from 'axios'
    import Reason from '@/components/Reason'
    import History from '@/components/layout/History'
    import CardTransactionStatus from '@/components/CardTransactions/status'
    import SelectedPaymentMethod from '@/components/layout/SelectedPaymentMethod'

    export default {
        name: 'CardAuthorizationPanel',
        components: {
            Alert,
            Badge,
            Boolean,
            Button,
            CardAuthorizationStatus,
            CardTransactionStatus,
            Dropdown,
            DropdownItem,
            History,
            ID,
            IdentifyMerchant,
            IdentifyType,
            Modal,
            Panel,
            Reason,
            Row,
            SelectedPaymentMethod,
            Sheet,
            Tab,
            Table,
            Tabs,
        },
        mixins: [Modals],
        props: {
            id: String,
        },
        data() {
            return {
                allowedPaymentMethods: null,
                allowedTypes: null,
                authorization: null,
                hasSubmitted: false,
                loading: true,
            }
        },
        computed: {
            authorized() {
                return (
                    this.authorization.status &&
                    (this.authorization.status.name === 'approved' || this.authorization.status.name === 'closed')
                )
            },
            captured() {
                return this.authorization && this.authorization.card_transactions_amount > 0
            },
            digitalWallet() {
                return this.authorization.digital_wallet
                    .replace(/_/g, ' ')
                    .split(' ')
                    .map((word) => word[0].toUpperCase() + word.substring(1))
                    .join(' ')
            },
        },
        methods: {
            fetchData() {
                this.loading = true

                // set requests
                const requestAuthorization = this.$api.cards.showAuthorization(this.id, {
                    includes: [
                        'user',
                        'card',
                        'card_transactions.payment_transactions',
                        'card_transactions_amount',
                        'overflow_amount',
                        'statuses',
                        'card.issuer',
                        'external_id',
                        'merchant',
                        'card.issuer.external_id',
                    ],
                })
                const requestAllowedTypes = this.$api.cards.getCAAllowedTypes(this.id)
                const requestAllowedPaymentMethods = this.$api.cards.getAllowedPaymentMethods(this.id)

                // make requests
                axios.all([requestAuthorization, requestAllowedTypes, requestAllowedPaymentMethods]).then(
                    axios.spread((...responses) => {
                        const responseAuthorization = responses[0]
                        const responseAllowedTypes = responses[1]
                        const responseAllowedPaymentMethods = responses[2]
                        // set data
                        this.authorization = responseAuthorization
                        this.allowedTypes = responseAllowedTypes.data
                        this.allowedPaymentMethods = responseAllowedPaymentMethods.data
                        // stop loading
                        this.loading = false
                    })
                )
            },
            reportAsFraudulent() {
                this.$api.cards
                    .updateAuthorization(this.id, {
                        fraudulent: true,
                    })
                    .then(() => {
                        Alerts.messageSuccess('Card authorization is reported as fraudulent')
                        this.fetchData()
                    })
                    .catch((error) => {
                        const errorTxt =
                            error && error.response && error.response.data
                                ? error.response.data.message
                                : 'An error occurred while trying to report the card authorization as fraudulent'
                        Alerts.notificationError(errorTxt)
                    })
            },
            merchantIdentified() {
                this.hasSubmitted = true
                this.fetchData()
                this.closeModal('setPartner')
            },
            typesAdded() {
                this.hasSubmitted = true
                this.fetchData()
                this.closeModal('identifyType')
            },
            openPartner(partner) {
                this.openPanel('partnerPanel', partner.id)
            },
            openUser(user) {
                this.openPanel('userPanel', user.id)
            },
            openCard(card) {
                this.openPanel('cardPanel', card.id)
            },
            openTransaction(transaction) {
                this.openPanel('cardTransactionPanel', transaction.id)
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
