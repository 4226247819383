<template>
    <Panel
        title="Payment Transaction"
        :loading="loading"
        @closePanel="$emit('closePanel', { refresh: refreshAfterClose })"
    >
        <Tabs v-if="transaction">
            <!-- Request -->
            <Tab name="Transaction" :selected="true">
                <Sheet class="mt-1" name="Details">
                    <Row name="Payable" v-if="transaction.payable_type">
                        <Payable
                            :type="transaction.payable_type"
                            :payable="transaction.payment_intent.payable"
                            clickable
                        />
                    </Row>
                </Sheet>
                <Sheet class="mt-1" name="Payment">
                    <Row name="Amount">{{ transaction.amount | currency }}</Row>
                    <Row name="Payment Method">
                        <SelectedPaymentMethod
                            :paymentMethod="{ payment_method: transaction.payment_method }"
                            smallIcons
                        />
                    </Row>
                    <Row name="Payment Intent">
                        <a href="#" @click="openPaymentIntent(transaction.payment_intent)" class="text-decoration-none">
                            {{ transaction.payment_intent.id }}
                        </a>
                    </Row>
                </Sheet>
                <Sheet class="mt-1" name="Request">
                    <Row name="Created date">{{ transaction.created_at | formatDate }}</Row>
                    <Row name="Transaction date">{{ transaction.transaction_at | formatDate }}</Row>
                    <Row name="Updated date">{{ transaction.updated_at | formatDate }}</Row>
                </Sheet>
                <ID name="Hexeko" :value="transaction.id" class="me-1" />
                <ID
                    v-if="transaction.external_id"
                    name="External"
                    :url="externalIdUrl"
                    :value="transaction.external_id"
                />
            </Tab>
        </Tabs>

        <template
            v-slot:actions
            v-if="$store.getters.userHasPermission('managePaymentTransactions') && !loading && transaction"
        >
            <Dropdown small title="Actions" position="right">
                <DropdownItem :disabled="transaction.amount <= 0" confirm @confirm="refund(transaction)" icon="undo-alt"
                    >Refund transaction</DropdownItem
                >
            </Dropdown>
        </template>
    </Panel>
</template>

<script>
    import {
        Panel,
        Sheet,
        Row,
        ID,
        Alerts,
        Dropdown,
        DropdownItem,
        Modal,
        Alert,
        Tabs,
        Tab,
    } from '@tech_hexeko/design-system'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import Payable from '../PaymentIntents/Payable'
    import SelectedPaymentMethod from '../layout/SelectedPaymentMethod/index'

    export default {
        name: 'PaymentTransactionPanel',
        props: {
            id: Number,
        },
        data() {
            return {
                loading: true,
                transaction: null,
                refreshAfterClose: false,
            }
        },
        components: {
            SelectedPaymentMethod,
            Panel,
            Sheet,
            Row,
            ID,
            Dropdown,
            DropdownItem,
            Modal,
            Alert,
            Tabs,
            Tab,
            Payable,
        },
        mixins: [Modals],
        computed: {
            externalIdUrl() {
                let url = null
                if (
                    this.transaction &&
                    this.transaction.external_id &&
                    typeof this.transaction.external_id === 'string' &&
                    this.transaction.external_id.startsWith('pi_')
                )
                    url = `https://dashboard.stripe.com/payments/${this.transaction.external_id}`
                return url
            },
        },
        methods: {
            fetchData() {
                this.loading = true
                // Load transaction
                this.$api.paymentTransactions
                    .show(this.id, {
                        include: ['payer', 'payment_intent.payable', 'payment_transaction'],
                    })
                    .then((transaction) => {
                        this.transaction = transaction
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response && error.response.data
                                ? error.response.data.message
                                : 'An error occurred while trying to load the payment transaction'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            openPaymentIntent(intent) {
                this.openPanel('PaymentIntentPanel', intent.id)
            },
            refund(transaction) {
                return this.$api.paymentTransactions
                    .refund(transaction.id)
                    .then(() => {
                        this.fetchData()
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response && error.response.data
                                ? error.response.data.message
                                : 'An error occurred while trying to refund the payment transaction'
                        Alerts.notificationError(errorTxt)
                    })
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
