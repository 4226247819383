<template>
    <Datatable
        name="Invoices"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
        :hover="false"
    >
        <template v-slot:filters>
            <Dropdown class="me-1" color="secondary" title="Filter by status" small>
                <li v-for="status in statuses" :key="`filter-${status.value}`">
                    <a
                        href="#"
                        @click.prevent="filterByStatus(status.value)"
                        class="dropdown-item text-xs"
                        :class="isActiveFilter(status.value) ? 'bg-primary text-white' : 'bg-white'"
                        >{{ status.text }}</a
                    >
                </li>
            </Dropdown>
        </template>
        <template v-slot:thead>
            <tr>
                <th>Date</th>
                <th>Reference</th>
                <th>Billing account</th>
                <th>Automatic payment</th>
                <th>Total</th>
                <th>Status</th>
                <th>Payment Status</th>
                <th
                    v-if="$store.getters.userHasAnyPermission(['manageInvoices', 'viewAnyBillingAccounts'])"
                    class="text-center"
                >
                    Actions
                </th>
            </tr>
        </template>
        <tr v-for="(invoice, index) in invoices" :key="'invoice-' + index">
            <td>{{ invoice.finalized_at | formatDate }}</td>
            <td>
                <a href="#" @click.prevent="open(invoice)">{{ invoice.reference || 'N/A' }}</a>
            </td>
            <td>{{ invoice.billing_account_name }}</td>
            <td>
                <Icon icon="check-circle" v-if="invoice.has_automatic_payment_enabled" color="var(--bs-success)" />
                <Icon icon="times-circle" v-else color="var(--bs-danger)" />
            </td>
            <td>{{ invoice.total | currency }}</td>
            <td>
                <InvoiceStatus :invoice="invoice" />
            </td>
            <td>
                <PaymentStatus :status="invoice.payment_status" />
            </td>
            <td
                v-if="$store.getters.userHasAnyPermission(['manageInvoices', 'viewAnyBillingAccounts'])"
                class="text-center"
            >
                <Button
                    @click="downloadInvoice(invoice.id)"
                    icon="download"
                    :loading="downloadLoading === invoice.id"
                    small
                />
            </td>
        </tr>
    </Datatable>
</template>

<script>
    import { Alerts, Datatable, Dropdown, Icon, Button } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import InvoiceStatus from '@/components/Invoices/status'
    import PaymentStatus from '@/components/Invoices/paymentStatus'

    export default {
        name: 'InvoicesList',
        mixins: [Modals],
        components: {
            Datatable,
            Dropdown,
            Icon,
            InvoiceStatus,
            PaymentStatus,
            Button,
        },
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
        },
        data() {
            return {
                currentStatus: null,
                downloadLoading: false,
                invoices: [],
                loading: true,
                pagination: { current_page: 1 },
                search: null,
                statuses: [
                    { value: 'canceled', text: 'Cancelled' },
                    { value: 'draft', text: 'Draft' },
                    { value: 'open', text: 'Open' },
                    { value: 'paid', text: 'Paid' },
                ],
            }
        },
        methods: {
            fetchData(search) {
                this.loading = true
                this.search = search
                this.$api.billing.invoices
                    .get(
                        this.mergeParams({
                            search: this.search,
                            page: this.pagination.current_page,
                            status: this.currentStatus,
                            order_by: 'date',
                            sort_by: 'desc',
                        })
                    )
                    .then((response) => {
                        this.invoices = response.data
                        this.pagination = response.meta.pagination
                        this.loading = false
                    })
            },
            filterByStatus(type) {
                this.currentStatus = this.currentStatus === type ? null : type
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            isActiveFilter(type) {
                return this.currentStatus === type
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            open(invoice) {
                let panel = this.openPanel('InvoicePanel', invoice.id)
                panel.promise.then((result) => {
                    if (result && result.refresh) this.fetchData(this.search)
                })
            },
            downloadInvoice(id) {
                this.downloadLoading = id
                this.$api.billing.invoices
                    .download(id)
                    .then((payment) => {
                        window.open(payment.file_url, '_blank')
                        setTimeout(() => (this.downloadLoading = false), 4000)
                    })
                    .catch((error) => {
                        let errorTxt = error && error.response ? error.response.data.message : 'An error has occurred'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.downloadLoading = false
                    })
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
