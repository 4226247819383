<template>
    <Datatable
        name="Licenses"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
        :hover="false"
    >
        <template v-slot:filters>
            <Dropdown class="me-1 mb-1" color="secondary" title="Filter by type" small>
                <li v-for="(type, technical_name) in licenseTypes" :key="technical_name">
                    <a
                        href="#"
                        class="dropdown-item text-xs"
                        :class="licenseType === technical_name ? 'bg-primary text-white' : 'bg-white'"
                        @click.prevent="filterByLicenseType(technical_name)"
                        >{{ type.name }}</a
                    >
                </li>
            </Dropdown>
        </template>
        <template v-slot:thead>
            <tr>
                <th>Reference</th>
                <th>Type</th>
                <th>Paid by</th>
                <th>Paid for</th>
                <th>Created at</th>
                <th>Used at</th>
                <th>Status</th>
                <th v-if="$store.getters.userHasPermission('manageLicenses')" class="text-center">Actions</th>
            </tr>
        </template>
        <tr v-for="(license, index) in licenses" valign="middle" :key="'license-' + index">
            <td>{{ license.reference }}</td>
            <td>{{ license.license_type }}</td>
            <td>{{ license.paid_by && license.paid_by.name ? license.paid_by.name : license.paid_by_id }}</td>
            <td>{{ license.paid_for && license.paid_for.name ? license.paid_for.name : license.paid_for_id }}</td>
            <td>{{ license.created_at | formatDate }}</td>
            <td>{{ license.used_at | formatDate }}</td>
            <td><InvoiceStatus :license="license"></InvoiceStatus></td>
            <td v-if="$store.getters.userHasPermission('manageLicenses')" class="text-center">
                <template v-if="!license.used_at">
                    <Spinner v-if="isLoading(license.id)" />
                    <a
                        v-else
                        href="#"
                        is="confirm"
                        tag="a"
                        class="text-danger"
                        @confirm="deleteLicense(license.id)"
                        title="Are you sure you want to delete this license?"
                    >
                        <Icon icon="trash-alt" />
                    </a>
                </template>
            </td>
        </tr>
    </Datatable>
</template>

<script>
    import { Datatable, Boolean, Alerts, Spinner, Dropdown } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import InvoiceStatus from './status'
    import types from './types'

    export default {
        name: 'Licenses',
        components: {
            Datatable,
            Spinner,
            InvoiceStatus,
            Dropdown,
        },
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            user: Number,
            hideActions: Boolean,
        },
        data() {
            return {
                loading: true,
                loadingIds: [],
                pagination: {
                    current_page: 1,
                },
                licenses: [],
                licenseTypes: types,
                licenseType: null,
            }
        },
        methods: {
            fetchData(search) {
                this.loading = true
                this.$api.users
                    .getLicenses(
                        this.user,
                        this.mergeParams({
                            search: search,
                            includes: ['paid_for', 'paid_by'],
                            license_type: this.licenseType,
                            page: this.pagination.current_page,
                        })
                    )
                    .then((response) => {
                        this.licenses = response.data
                        this.pagination = response.meta.pagination
                        this.loading = false
                    })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            deleteLicense(id) {
                this.loadingIds.push(id)
                this.$api.licenses
                    .delete(id)
                    .then(() => {
                        Alerts.notificationSuccess('License deleted')
                        this.pagination.current_page = 1
                        this.fetchData()
                    })
                    .catch((error) => {
                        const errorTxt =
                            error && error.response && error.response.data && error.response.data.message
                                ? error.response.data.message
                                : 'An error occurred while trying to delete the license'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        const idIndex = this.loadingIds.indexOf(id)
                        this.loadingIds.splice(idIndex, 1)
                    })
            },
            filterByLicenseType(licenseType) {
                this.licenseType = this.licenseType === licenseType ? null : licenseType
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            isLoading(id) {
                return this.loadingIds.includes(id)
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
