<template>
    <Datatable
        name="Credit Notes"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
        @search="fetchData"
        searchClass="d-none"
    >
        <template v-slot:thead>
            <tr>
                <th>Date</th>
                <th>Billing account</th>
                <th>Amount</th>
                <th>Memo</th>
                <th>&nbsp;</th>
            </tr>
        </template>
        <tr v-for="creditNote in creditNotes" :key="creditNote.id" @click="openCreditNote(creditNote.id)">
            <td>{{ creditNote.created_at | formatDate }}</td>
            <td>
                <template v-if="creditNote.billing_account">{{ creditNote.billing_account.name }}</template>
            </td>
            <td>{{ creditNote.amount | currency }}</td>
            <td>
                <template v-if="creditNote.memo">{{ creditNote.memo }}</template>
            </td>
            <td class="text-end"><Icon icon="angle-right" /></td>
        </tr>
    </Datatable>
</template>

<script>
    import { Alerts, Datatable } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'

    export default {
        name: 'CreditNotes',
        mixins: [Modals],
        components: {
            Datatable,
        },
        data() {
            return {
                creditNotes: [],
                loading: true,
                pagination: { current_page: 1 },
            }
        },
        props: {
            hideActions: Boolean,
            params: {
                type: Object,
                default: () => ({}),
            },
        },
        methods: {
            fetchData() {
                this.loading = true
                const params = this.mergeParams({
                    page: this.pagination.current_page,
                    sort_by: 'desc',
                    order_by: 'date',
                    includes: ['billing_account'],
                })
                const request = this.params.billing_account_id
                    ? this.$api.billing.billingAccounts.getCreditNotes(this.params.billing_account_id, params)
                    : this.$api.billing.creditNotes.get(params)
                Promise.resolve(request)
                    .then((response) => {
                        this.creditNotes = response.data
                        this.pagination = response.meta.pagination
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response && error.response.data
                                ? error.response.data.message
                                : 'An error occurred while trying to fetch credit notes'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            openCreditNote(id) {
                this.openPanel('CreditNotePanel', id)
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
