/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: "eu-west-3",
    aws_cognito_region: "eu-west-3",
    aws_user_pools_id: "eu-west-3_pkUKn72p4",
    aws_user_pools_web_client_id: "1vkneid5v4bplo21dvvb9hl8ah",
    oauth: {
        domain: "develop-hexeko-main-api.auth.eu-west-3.amazoncognito.com"
    },
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: [],
    aws_cognito_mfa_configuration: "OPTIONAL",
    aws_cognito_mfa_types: ["SMS", "TOTP"],
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: ["REQUIRES_LOWERCASE", "REQUIRES_UPPERCASE", "REQUIRES_NUMBERS", "REQUIRES_SYMBOLS"]
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
    aws_content_delivery_bucket: "admin-amplify-dev",
    aws_content_delivery_bucket_region: "eu-west-3",
    aws_content_delivery_url: "https://d2ed3qgqitrh5q.cloudfront.net"
};
  
  
  export default awsmobile;