export function formatBoolean(data) {
    if (data === true) return 1;
    if (data === false) return 0;
    return null;
}

export function getTypesArrayFromWallet(wallet) {
    if (!wallet || !wallet.types) return [];
    return wallet.types.map((type) => {
        return type.technical_name;
    });
}

export function toggleBoolean(data) {
    switch (data) {
        case null:
            return true;
        case true:
            return false;
        default:
            return null;
    }
}
