<template>
    <nav ref="navbar" class="navbar navbar-expand-lg navbar-light navbar-main bg-light">
        <div class="container-fluid">
            <router-link class="navbar-brand" :to="{ name: 'dashboard' }">
                <Logo logomark width="30" />
            </router-link>
            <button class="navbar-toggler" type="button" @click="openedNav = !openedNav">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div
                class="collapse navbar-collapse"
                id="navbarNav"
                :class="{ show: openedNav }"
                v-if="filteredRoutes.length > 0"
            >
                <ul class="navbar-nav mt-1">
                    <template v-for="route in filteredRoutes">
                        <li v-if="!route.children" class="nav-item ms-3" :key="route.meta.label">
                            <router-link class="nav-link" :to="{ name: route.name }">
                                <Icon v-if="route.meta.icon" :icon="route.meta.icon" /> {{ route.meta.label }}
                            </router-link>
                        </li>
                        <li v-else class="nav-item dropdown ms-3" :key="'children-' + route.meta.label">
                            <a
                                href="#"
                                class="nav-link dropdown-toggle"
                                :class="{ 'router-link-active': isParentActive(route) }"
                                @click.prevent="toggleTab(route.meta.label)"
                            >
                                <Icon v-if="route.meta.icon" :icon="route.meta.icon" /> {{ route.meta.label }}
                            </a>
                            <ul class="dropdown-menu d-block text-xs" v-if="tabIsShown(route.meta.label)">
                                <li v-for="child in route.children" :key="child.name">
                                    <router-link
                                        class="nav-link px-2"
                                        :to="{ name: child.name }"
                                        @click.native="closeTab"
                                        exact-active-class="nav-link-active"
                                        exact
                                    >
                                        <Icon v-if="child.meta.icon" :icon="child.meta.icon" /> {{ child.meta.label }}
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
    import { Logo } from '@tech_hexeko/design-system'
    import routes from '../../../routes.js'

    export default {
        name: 'Navbar',
        data() {
            return {
                openedNav: false,
                tab: null,
                usersChildMap: {
                    '/users': ['/groups', '/wallets'],
                },
            }
        },
        components: {
            Logo,
        },
        computed: {
            filteredRoutes() {
                return routes
                    .filter((route) => this.isValidRoute(route))
                    .map((route) => ({
                        ...route,
                        children: (route.children || []).filter((child) => this.isValidRoute(child)),
                    }))
            },
        },
        methods: {
            toggleTab(tab) {
                this.tab = this.tabIsShown(tab) ? null : tab
            },
            isParentActive(route) {
                const currentPath = this.$route.matched
                const additionalChildren = this.usersChildMap[route.path] || []

                return (
                    additionalChildren.some((childPath) => currentPath.some(({ path }) => path === childPath)) ||
                    currentPath.some(({ path }) => route.path === path)
                )
            },

            tabIsShown(tab) {
                return this.tab === tab
            },
            closeTab() {
                this.tab = null
            },
            isValidRoute(route) {
                return route.meta?.label && this.hasPermission(route.meta?.permissions)
            },
            hasPermission(permissions = []) {
                if (!permissions || permissions.length === 0) {
                    return true
                }
                return this.$store.getters.userHasAnyPermission(permissions)
            },
            handleClickOutside(event) {
                if (!this.$refs.navbar.contains(event.target)) {
                    this.closeTab()
                }
            },
        },
        mounted() {
            document.addEventListener('click', this.handleClickOutside)
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleClickOutside)
        },
    }
</script>
<style scoped>
    .navbar-toggler,
    .navbar-toggler:focus {
        border: none !important;
        box-shadow: none !important;
    }
    .dropdown-menu {
        --bs-dropdown-min-width: 11rem;
    }
</style>
