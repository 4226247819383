<template>
    <div>
        <Datatable
            name="Credit cards"
            @search="fetchData"
            :pagination.sync="pagination"
            :loading="loading"
            :hover="false"
        >
            <template v-slot:thead>
                <tr>
                    <th>Type</th>
                    <th>Number</th>
                    <th>Brand</th>
                    <th>Expiration</th>
                    <th>Default</th>
                    <th>Added at</th>
                    <th v-if="$store.getters.userHasPermission('managePaymentMethods')" class="text-center">Actions</th>
                </tr>
            </template>
            <tr v-for="card in cards">
                <td>
                    <Badge class="text-capitalize">{{ card.type }}</Badge>
                </td>
                <td><Icon icon="credit-card" /> •••• {{ card.last4 }}</td>
                <td>{{ card.brand }}</td>
                <td>{{ card.exp_month }} / {{ card.exp_year }}</td>
                <td><Boolean :value="card.is_default_method" /></td>
                <td>{{ card.created_at | formatDate }}</td>
                <td v-if="$store.getters.userHasPermission('managePaymentMethods')" class="text-center">
                    <Spinner v-if="isLoading(card.id)" />
                    <a
                        v-else
                        href="#"
                        class="text-danger"
                        is="confirm"
                        tag="a"
                        @confirm="deleteCreditCard(card.id)"
                        title="Are you sure you want to delete this payment method?"
                        ><Icon icon="trash-alt" /><span class="visually-hidden">Delete</span></a
                    >
                </td>
            </tr>
        </Datatable>
    </div>
</template>

<script>
    import { Alerts, Badge, Boolean, Datatable, Spinner } from '@tech_hexeko/design-system'

    export default {
        name: 'CreditCards',
        components: {
            Badge,
            Boolean,
            Datatable,
            Spinner,
        },
        props: {
            user: Number,
        },
        data() {
            return {
                loading: false,
                search: null,
                pagination: {
                    current_page: 1,
                },
                cards: [],
                loadingIds: [],
            }
        },
        methods: {
            deleteCreditCard(id) {
                this.loadingIds.push(id)
                this.$api.creditCards
                    .delete(id)
                    .then(() => {
                        Alerts.messageSuccess('The credit card has been deleted')
                        this.fetchData()
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response
                                ? error.response.data.message
                                : 'An error occurred when trying to delete this credit card'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        const idIndex = this.loadingIds.indexOf(id)
                        this.loadingIds.splice(idIndex, 1)
                    })
            },
            fetchData(search) {
                this.loading = true
                this.search = search
                this.$api.users
                    .getCreditCards(this.user, {
                        search: this.search,
                    })
                    .then((response) => {
                        this.cards = response.data
                        this.pagination = {
                            total: response.data.length,
                        }
                        this.loading = false
                    })
            },
            isLoading(id) {
                return this.loadingIds.includes(id)
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
