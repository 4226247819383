<template>
    <Panel
        :title="wallet && wallet.name ? wallet.name : 'Wallet'"
        :loading="loading"
        @closePanel="$emit('closePanel', { refresh: hasSubmitted })"
    >
        <TypesIcons :types="typesFiltered" class="mb-1" position="left" />

        <Tabs v-if="!loading">
            <!-- Wallet -->
            <Tab name="Wallet" :selected="true">
                <div v-if="reloading" class="my-2"><Spinner /> Reloading…</div>
                <div class="row">
                    <div class="col-6">
                        <Stat :value="wallet.balance" :decimals="true" legend="Wallet balance" icon="euro-sign" />
                    </div>
                    <div class="col-6">
                        <Stat
                            :value="wallet.total_deposit"
                            :decimals="true"
                            color="info"
                            legend="Total deposit"
                            icon="download"
                        />
                    </div>
                </div>
                <div class="my-2">
                    <WalletType :wallet="wallet" />
                    <WalletStatus class="ms-1" :wallet="wallet" />
                </div>
                <Sheet>
                    <Row name="Name" v-if="wallet.name">
                        {{ wallet.name }}
                    </Row>
                    <Row name="Source">
                        <a
                            href="#"
                            @click.prevent="openMorph(wallet.source_type, wallet.source.id)"
                            class="text-decoration-none"
                            ><WalletIcon :type="wallet.source_type" /> {{ wallet.source.name }}</a
                        >
                    </Row>
                    <Row name="Creditable">
                        <a
                            href="#"
                            @click.prevent="openMorph(wallet.creditable_type, wallet.creditable.id)"
                            class="text-decoration-none"
                            ><WalletIcon :type="wallet.creditable_type" /> {{ wallet.creditable.name }}</a
                        >
                    </Row>
                    <Row name="Cards linked">{{ wallet.cards.length }}</Row>
                    <Row name="Validity period" v-if="wallet.start_date && wallet.expiration_date">
                        From {{ wallet.start_date | formatDate('DD/MM/YYYY') }} to
                        {{ wallet.expiration_date | formatDate('DD/MM/YYYY') }}
                    </Row>
                    <Row name="Next expiration date" v-if="wallet.next_expiration_date">
                        {{ wallet.next_expiration_date | formatDate('DD/MM/YYYY') }}
                    </Row>
                    <Row name="Created at">{{ wallet.created_at | formatDate }}</Row>
                    <Row name="Updated at">{{ wallet.updated_at | formatDate }}</Row>
                </Sheet>
                <Sheet name="Balances">
                    <Row name="Balance">{{ wallet.balance | currency }} / {{ wallet.total_deposit | currency }}</Row>
                    <Row name="Spendable balance"
                        >{{ wallet.spendable_amount | currency }} /
                        {{ wallet.initial_spendable_amount | currency }}</Row
                    >
                    <Row name="Frozen balance">{{ wallet.frozen_amount | currency }}</Row>
                </Sheet>
            </Tab>

            <!-- Balances -->
            <Tab name="Balances">
                <div class="row mb-2">
                    <div class="col-6">
                        <Stat :value="wallet.balance" :decimals="true" legend="Wallet balance" icon="euro-sign" />
                    </div>
                    <div class="col-6">
                        <Stat
                            :value="wallet.total_deposit"
                            :decimals="true"
                            color="info"
                            legend="Total deposit"
                            icon="download"
                        />
                    </div>
                </div>
                <Table striped borderless>
                    <template v-slot:thead>
                        <tr>
                            <th width="25%">Start date</th>
                            <th width="25%">End date</th>
                            <th colspan="2">Balance</th>
                        </tr>
                    </template>
                    <tr v-for="(balance, index) in wallet.balances" :key="'balance-' + index">
                        <td>{{ balance.start_date | formatDate }}</td>
                        <td>{{ balance.end_date | formatDate }}</td>
                        <td>{{ balance.balance | currency }}</td>
                        <td width="40%">
                            <Progress
                                :total="balance.deposit_amount"
                                :count="balance.deposit_amount - balance.balance"
                            />
                        </td>
                    </tr>
                </Table>
            </Tab>

            <!-- Transactions -->
            <Tab
                name="Transactions"
                v-if="$store.getters.userHasAnyPermission(['showPaymentTransactions', 'managePaymentTransactions'])"
            >
                <Stat :value="transactions.length" legend="Transactions count" class="mb-3"></Stat>
                <WalletTransactions :id="wallet.id" />
            </Tab>

            <!-- Types -->
            <Tab name="Types" v-if="$store.getters.userHasPermission('manageTypes')">
                <TypesSelector :types="wallet.types" @save="typesSave"></TypesSelector>
            </Tab>

            <!-- Spending limits -->
            <Tab name="Spending limits" v-if="$store.getters.userHasPermission('manageWallets')">
                <SpendingLimits :wallet="wallet.id" ref="spendingLimits" />
            </Tab>

            <!-- Cards linked -->
            <Tab name="Linked cards" v-if="wallet.cards.length > 0">
                <Cards :wallet="wallet.id" />
            </Tab>
        </Tabs>

        <div v-if="wallet" class="mt-4">
            <ID name="Hexeko" :value="wallet.id" />
        </div>

        <template v-slot:actions v-if="$store.getters.userHasPermission('manageWallets')">
            <!-- Actions -->
            <Dropdown small title="Actions" position="right">
                <DropdownItem @click="openModal('deposit')" icon="arrow-alt-circle-down">Deposit</DropdownItem>
                <DropdownItem @click="openModal('withdraw')" icon="arrow-alt-circle-up">Withdraw</DropdownItem>
                <DropdownItem @click="openModal('spendingLimit')" icon="calculator">Add spending limit</DropdownItem>
                <DropdownItem
                    confirm
                    @confirm="disable"
                    class="text-danger"
                    icon="times-circle"
                    v-if="$store.getters.userHasPermission('manageWallets') && wallet.enabled"
                >
                    Disable
                </DropdownItem>
                <DropdownItem
                    confirm
                    @confirm="enable"
                    class="text-danger"
                    icon="check-circle"
                    v-if="$store.getters.userHasPermission('manageWallets') && !wallet.enabled"
                >
                    Enable
                </DropdownItem>
            </Dropdown>

            <!-- Deposit -->
            <Modal v-if="isModalOpen('deposit')" @close="closeModal('deposit')" title="Deposit on wallet">
                <Deposit :wallet="wallet.id" @submitted="depositSubmitted"></Deposit>
            </Modal>

            <!-- Withdraw -->
            <Modal v-if="isModalOpen('withdraw')" @close="closeModal('withdraw')" title="Withdraw on wallet">
                <Withdraw :wallet="wallet.id" @submitted="withdrawSubmitted"></Withdraw>
            </Modal>

            <!-- SpendingLimit -->
            <Modal
                v-if="isModalOpen('spendingLimit')"
                @close="closeModal('spendingLimit')"
                title="Daily spending limit on wallet"
            >
                <SpendingLimit :wallet="wallet.id" @submitted="spendingLimitSubmitted"></SpendingLimit>
            </Modal>
        </template>
    </Panel>
</template>

<script>
    import {
        Panel,
        Tabs,
        Tab,
        Sheet,
        Row,
        ID,
        Stat,
        Modal,
        Spinner,
        Table,
        Progress,
        Alerts,
        Dropdown,
        DropdownItem,
    } from '@tech_hexeko/design-system'
    import WalletTransactions from '@/components/WalletTransactions'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import Deposit from '@/components/Wallets/Deposit'
    import Withdraw from '@/components/Wallets/Withdraw'
    import SpendingLimits from '@/components/Wallets/SpendingLimits'
    import WalletIcon from './WalletIcon'
    import WalletType from './WalletType'
    import WalletStatus from './Status'
    import SpendingLimit from './SpendingLimit'
    import TypesIcons from '@/components/Types/Icons.vue'
    import TypesSelector from '../Types/Selector'
    import Cards from '../Cards'
    import { getTypesArrayFromWallet } from '@/utils/query'

    export default {
        name: 'WalletPanel',
        mixins: [Modals],
        components: {
            Panel,
            Tabs,
            Tab,
            Sheet,
            Row,
            ID,
            Stat,
            WalletTransactions,
            Modal,
            Deposit,
            Withdraw,
            Dropdown,
            DropdownItem,
            Spinner,
            TypesSelector,
            SpendingLimits,
            WalletIcon,
            WalletType,
            WalletStatus,
            Table,
            Progress,
            TypesIcons,
            SpendingLimit,
            Cards,
        },
        props: {
            id: [Number, String],
        },
        data() {
            return {
                loading: true,
                reloading: false,
                hasSubmitted: false,
                wallet: {},
                transactions: [],
            }
        },
        computed: {
            typesFiltered() {
                return getTypesArrayFromWallet(this.wallet)
            },
        },
        methods: {
            fetchData(id = this.id) {
                this.loading = true
                this.reloading = false
                this.$api.wallets
                    .show(id, {
                        includes: [
                            'scope',
                            'types.slugs',
                            'total_deposit',
                            'types',
                            'cards',
                            'spendable_amount',
                            'initial_spendable_amount',
                            'frozen_amount',
                        ],
                    })
                    .then((response) => {
                        this.wallet = response
                    })
                    .finally(() => {
                        this.loading = false
                    })

                this.$api.wallets.getTransactions(id).then((response) => {
                    this.transactions = response.data
                })
            },
            waitThenFetchData() {
                this.reloading = true
                setTimeout(() => {
                    this.fetchData()
                }, 7500)
            },
            typesSave(types) {
                this.$api.wallets.setTypes(this.id, { types: types }).then(() => {
                    Alerts.messageSuccess('Types saved')
                    this.typesSubmitted()
                })
            },
            typesSubmitted() {
                this.hasSubmitted = true
                this.fetchData()
            },
            depositSubmitted() {
                this.closeModal('deposit')
                this.hasSubmitted = true
                this.waitThenFetchData()
            },
            withdrawSubmitted() {
                this.closeModal('withdraw')
                this.hasSubmitted = true
                this.waitThenFetchData()
            },
            spendingLimitSubmitted() {
                this.closeModal('spendingLimit')
                this.hasSubmitted = true
                this.$refs['spendingLimits'].fetchData()
            },
            openMorph(type, id) {
                if (type === 'group') {
                    this.openPanel('groupPanel', id)
                }
                if (type === 'user') {
                    this.openPanel('userPanel', id)
                }
            },
            disable() {
                this.$api.wallets.disable(this.id).then(() => {
                    Alerts.notificationSuccess('Successfully disabled')
                    this.fetchData()
                })
            },
            enable() {
                this.$api.wallets.enable(this.id).then(() => {
                    Alerts.notificationSuccess('Successfully enabled')
                    this.fetchData()
                })
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
