<template>
    <Badge v-if="quote.processing_finished_at" color="success" :large="large" icon="check-circle" v-tooltip="`Finished at ${moment(quote.processing_finished_at).format('lll')}`">Processed</Badge>
    <Badge v-else-if="quote.processing_started_at" color="warning" :large="large" icon="spinner" v-tooltip="`Started at ${moment(quote.processing_started_at).format('lll')}`">Processing</Badge>
    <Badge v-else-if="quote.funded_at && quote.funded_by" color="dark" :large="large" icon="coins" v-tooltip="`Funded at ${moment(quote.funded_at).format('lll')}`">Funded</Badge>
    <Badge v-else-if="quote.funded_at" color="dark" :large="large" icon="coins" v-tooltip="`Funded at ${moment(quote.funded_at).format('lll')}`">Presumed funded</Badge>
    <Badge v-else-if="quote.accepted_at" color="primary" :large="large" icon="check-circle" v-tooltip="`Accepted at ${moment(quote.accepted_at).format('lll')}`">Accepted</Badge>
    <Badge v-else-if="!quote.finalized_at && !quote.accepted_at" color="secondary" :large="large" icon="file-contract" v-tooltip="`Created at ${moment(quote.created_at).format('lll')}`"
        >Draft</Badge
    >
    <Badge v-else color="warning" :large="large" icon="file-contract" v-tooltip="`Finalized at ${moment(quote.finalized_at).format('lll')}`">Quote</Badge>
</template>
<script>
    import { Badge } from '@tech_hexeko/design-system'
    import moment from 'moment'

    export default {
        name: 'QuoteStatus',
        data() {
            return {
                moment: moment,
            }
        },
        props: {
            quote: Object,
            large: Boolean,
        },
        components: {
            Badge,
        },
    }
</script>
