<template>
    <div>
        <Datatable name="Wallets" :showPagination="false" :loading="loadingWallets" search-class="d-none" class="mb-2">
            <template v-slot:thead>
                <tr>
                    <th></th>
                    <th>ID</th>
                    <th></th>
                    <th>Wallet&nbsp;type</th>
                    <th>Source</th>
                    <th>Balance</th>
                    <th>Expiration</th>
                    <th>Linked&nbsp;on</th>
                    <th>Updated&nbsp;at</th>
                    <th></th>
                </tr>
            </template>
            <tr
                v-for="(wallet, index) in allowedWallets"
                class="align-middle cursor-pointer"
                :key="index"
                @click="openWallet(wallet.id)"
            >
                <td @click.stop="toggleSelect(wallet)" v-if="$store.getters.userHasPermission('manageWallets')">
                    <ToggleCheckbox :value="isSelected(wallet)" />
                </td>
                <td>
                    <div class="text-xxs text-muted">
                        <span :title="isWalletLinked(wallet) ? 'Wallet linked to the card' : ''">
                            <Icon icon="link" v-if="isWalletLinked(wallet)" />
                            {{ wallet.id }}
                        </span>
                    </div>
                </td>
                <td class="pe-0">
                    <TypesIcons :types="getTypesArrayFromWallet(wallet)" position="left" small />
                </td>
                <td class="ps-0">
                    <div class="text-xxs text-muted">
                        <span v-if="wallet.name">{{ wallet.name }}</span>
                        <span v-else>0 type defined</span>
                    </div>
                </td>
                <td>
                    <WalletIcon :type="wallet.source_type" />
                    <span v-if="wallet.source"> {{ wallet.source.name }}</span> <span class="text-muted"> to </span>
                    <WalletIcon :type="wallet.creditable_type" />
                    <span v-if="wallet.creditable"> {{ wallet.creditable.name }}</span>
                </td>
                <td>{{ wallet.balance | currency }}</td>
                <td>{{ wallet.expiration_date | formatDate('DD/MM/YYYY') }}</td>
                <td>{{ getLinkedWalletCreatedAt(wallet.id) | formatDate('DD/MM/YYYY') }}</td>
                <td>{{ getLinkedWalletUpdatedAt(wallet.id) | formatDate('DD/MM/YYYY') }}</td>
                <td><Icon icon="angle-right" /></td>
            </tr>
        </Datatable>

        <template v-if="$store.getters.userHasPermission('manageWallets') && allowedWallets.length > 0">
            <Button
                is="confirm"
                @confirm="linkWallets()"
                small
                color="success"
                icon="check-circle"
                :loading="acceptLoading"
            >
                Link selected wallets to the card
            </Button>
        </template>
    </div>
</template>
<script>
    import { Datatable, ToggleCheckbox, Button } from '@tech_hexeko/design-system'
    import WalletIcon from '@/components/Wallets/WalletIcon'
    import TypesIcons from '@/components/Types/Icons.vue'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import SelectAll from '@/mixins/SelectAll'
    import { getTypesArrayFromWallet } from '@/utils/query'

    export default {
        props: {
            card: String,
            user: Number,
        },
        data() {
            return {
                loadingWallets: true,
                acceptLoading: false,
                linkedWallets: [],
                allowedWallets: [],
            }
        },
        components: {
            Datatable,
            ToggleCheckbox,
            WalletIcon,
            TypesIcons,
            Button,
        },
        mixins: [Modals, SelectAll],
        methods: {
            getTypesArrayFromWallet,
            isWalletLinked(wallet) {
                return this.linkedWallets.find((item) => item.id === wallet.id)
            },
            fetch() {
                this.fetchLinkedWallets()
                this.fetchAllowedWallets()
            },
            fetchLinkedWallets() {
                this.$api.cards
                    .getLinkedWallets(this.card, {
                        includes: ['types.slugs'],
                    })
                    .then((response) => {
                        this.linkedWallets = response.data
                        this.selected = this.linkedWallets.map((item) => item.id)
                    })
            },
            fetchAllowedWallets() {
                this.loadingWallets = true
                this.$api.cards
                    .getAllowedWallets(this.card, {
                        includes: ['types.slugs'],
                    })
                    .then((response) => {
                        this.loadingWallets = false
                        this.allowedWallets = response.data
                    })
                    .catch(() => {
                        this.loadingWallets = false
                    })
            },
            linkWallets() {
                this.acceptLoading = true
                this.$api.cards
                    .setLinkedWallets(this.card, { wallets: this.allIdsSelected() })
                    .then(() => {
                        this.fetch()
                    })
                    .finally(() => {
                        this.acceptLoading = false
                    })
            },
            allIds() {
                return this.$options.filters.pluck(this.allowedWallets, 'id')
            },
            openWallet(id) {
                this.openPanel('walletPanel', id)
            },
            getLinkedWalletCreatedAt(id) {
                let linkedWallet = this.linkedWallets.find((item) => item.id === id)
                if (linkedWallet) return linkedWallet.wallet_card_created_at
                return null
            },
            getLinkedWalletUpdatedAt(id) {
                let linkedWallet = this.linkedWallets.find((item) => item.id === id)
                if (linkedWallet) return linkedWallet.wallet_card_updated_at
                return null
            },
        },
        mounted() {
            this.fetch()
        },
    }
</script>
