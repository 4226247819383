<template>
    <div>
        <Datatable
            name="Wallets"
            @search="fetchData"
            :pagination.sync="pagination"
            :loading="loading"
            :hideActions="hideActions"
        >
            <template v-slot:filters v-if="!hideFilters">
                <Dropdown
                    v-if="filterCreditables.length > 0"
                    class="me-1"
                    color="secondary"
                    title="Filter by creditable types"
                    small
                >
                    <li v-for="(filter, index) in filterCreditables" :key="'filterCreditable-' + index">
                        <a
                            href="#"
                            class="dropdown-item text-xs"
                            :class="creditableType === filter.value ? 'bg-primary text-white' : 'bg-white'"
                            @click.prevent="filterByCreditableType(filter.value)"
                        >
                            {{ filter.text }}
                        </a>
                    </li>
                </Dropdown>
                <Dropdown
                    v-if="filterSources.length > 0 && params.source_type !== 'group'"
                    class="me-1"
                    color="secondary"
                    title="Filter by source types"
                    small
                >
                    <li v-for="(filter, index) in filterSources" :key="'filterSource-' + index">
                        <a
                            href="#"
                            class="dropdown-item text-xs"
                            :class="sourceType === filter.value ? 'bg-primary text-white' : 'bg-white'"
                            @click.prevent="filterBySourceType(filter.value)"
                        >
                            {{ filter.text }}
                        </a>
                    </li>
                </Dropdown>
                <Dropdown
                    v-if="filterBalances.length > 0"
                    class="me-1"
                    color="secondary"
                    title="Filter by balance"
                    small
                >
                    <li v-for="(filter, index) in filterBalances" :key="'filterBalance-' + index">
                        <a
                            href="#"
                            class="dropdown-item text-xs"
                            :class="withBalance === filter.value ? 'bg-primary text-white' : 'bg-white'"
                            @click.prevent="filterByBalances(filter.value)"
                        >
                            {{ filter.text }}
                        </a>
                    </li>
                </Dropdown>
                <Button
                    class="me-1"
                    @click="toggleFilterEnabled"
                    :color="enabledOnly ? 'success' : enabledOnly === false ? 'danger' : 'secondary'"
                    small
                    >Enabled</Button
                >
                <Button
                    class="me-1"
                    @click="toggleFilterTrashed"
                    :color="trashedOnly ? 'success' : trashedOnly === false ? 'danger' : 'secondary'"
                    small
                    >Trashed</Button
                >
            </template>
            <template v-slot:thead>
                <tr>
                    <th v-if="$store.getters.userHasPermission('manageWallets')"></th>
                    <th>ID</th>
                    <th>Types</th>
                    <th>Source</th>
                    <th>Balance</th>
                    <th>Expiration date</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </template>
            <tr
                v-for="(wallet, index) in wallets"
                :class="{ 'cursor-pointer': !wallet.deleted_at, 'no-hover': wallet.deleted_at }"
                @click.prevent="!wallet.deleted_at && open(wallet)"
                :key="index"
            >
                <td @click.stop="toggleSelect(wallet)" v-if="$store.getters.userHasPermission('manageWallets')">
                    <ToggleCheckbox :value="isSelected(wallet)" v-if="!wallet.deleted_at" />
                </td>
                <td>
                    <code>{{ wallet.id }}</code>
                </td>
                <td class="dt-wallet-icons">
                    <TypesIcons :types="getTypesArrayFromWallet(wallet)" position="left" small />
                </td>
                <td>
                    <WalletIcon :type="wallet.source_type" /><template v-if="wallet.source.name">
                        {{ wallet.source.name }}</template
                    >
                    <span class="text-muted"> to </span>
                    <WalletIcon :type="wallet.creditable_type" />
                    <template v-if="wallet.creditable"> {{ wallet.creditable.name }}</template>
                    <div class="text-xxs text-muted">
                        <span v-if="wallet.name">{{ wallet.name }}</span>
                        <span v-else>0 type defined</span>
                    </div>
                </td>
                <td>{{ wallet.balance | currency }}</td>
                <td>{{ wallet.expiration_date | formatDate('DD/MM/YYYY') }}</td>
                <td><WalletStatus :wallet="wallet" /></td>
                <td class="text-right"><Icon icon="angle-right" v-if="!wallet.deleted_at" /></td>
            </tr>
            <template v-slot:actions v-if="$store.getters.userHasPermission('manageWallets')">
                <SelectAllButton
                    :metaSelected="metaSelected"
                    @selectAll="selectAll"
                    @unselectAll="unselectAll"
                    @reset="reset"
                >
                    <DropdownItem
                        icon="arrow-alt-circle-down"
                        :disabled="metaSelected.noneSelected"
                        @click="openModal('deposit')"
                        >Deposit</DropdownItem
                    >
                    <DropdownItem
                        icon="arrow-alt-circle-up"
                        :disabled="metaSelected.noneSelected"
                        @click="openModal('withdraw')"
                        >Withdraw</DropdownItem
                    >
                    <DropdownItem
                        icon="calculator"
                        :disabled="metaSelected.noneSelected"
                        @click="openModal('spendingLimit')"
                        >Add spending limit</DropdownItem
                    >
                    <DropdownItem icon="check-circle" :disabled="metaSelected.noneSelected" @click="bulkEnable">
                        Enable
                    </DropdownItem>
                    <DropdownItem icon="times-circle" :disabled="metaSelected.noneSelected" @click="bulkDisable">
                        Disable
                    </DropdownItem>
                </SelectAllButton>

                <!-- Deposit -->
                <Modal v-if="isModalOpen('deposit')" @close="closeModal('deposit')" title="Deposit on wallets">
                    <Deposit @submitted="bulkDeposit" preventSubmit></Deposit>
                </Modal>

                <!-- Withdraw -->
                <Modal v-if="isModalOpen('withdraw')" @close="closeModal('withdraw')" title="Withdraw from wallets">
                    <Withdraw @submitted="bulkWithdraw" preventSubmit></Withdraw>
                </Modal>

                <!-- SpendingLimit -->
                <Modal
                    v-if="isModalOpen('spendingLimit')"
                    @close="closeModal('spendingLimit')"
                    title="Spending limit on wallets"
                >
                    <SpendingLimit @submitted="bulkSpendingLimit" preventSubmit></SpendingLimit>
                </Modal>
            </template>
        </Datatable>
    </div>
</template>

<script>
    import {
        Alerts,
        Button,
        Datatable,
        ToggleCheckbox,
        DropdownItem,
        Modal,
        Dropdown,
    } from '@tech_hexeko/design-system'
    import { omitBy, isNil } from 'lodash'
    import WalletIcon from './WalletIcon'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import SelectAll from '@/mixins/SelectAll'
    import { formatBoolean, getTypesArrayFromWallet, toggleBoolean } from '@/utils/query'
    import SelectAllButton from '@/components/layout/SelectAllButton'
    import TypesIcons from '@/components/Types/Icons.vue'
    import Deposit from './Deposit'
    import Withdraw from './Withdraw'
    import SpendingLimit from './SpendingLimit'
    import WalletStatus from './Status'

    export default {
        name: 'Wallets',
        mixins: [Modals, SelectAll],
        components: {
            Button,
            Datatable,
            WalletIcon,
            SelectAllButton,
            Deposit,
            ToggleCheckbox,
            DropdownItem,
            Modal,
            Withdraw,
            TypesIcons,
            SpendingLimit,
            Dropdown,
            WalletStatus,
        },
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Boolean,
            hideFilters: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                loading: true,
                search: null,
                pagination: {
                    current_page: 1,
                },
                wallets: [],
                creditableType: null,
                sourceType: this.params.source_type,
                filterSources: [
                    { value: 'user', text: 'User' },
                    { value: 'group', text: 'Group' },
                ],
                filterCreditables: [
                    { value: 'user', text: 'User' },
                    { value: 'group', text: 'Group' },
                ],
                filterBalances: [
                    { value: 'positive', text: 'Positive balance' },
                    { value: 'negative', text: 'Negative balance' },
                    { value: 'none', text: 'Balance at 0' },
                ],
                withBalance: null,
                trashedOnly: null,
                enabledOnly: null,
            }
        },
        methods: {
            getTypesArrayFromWallet,
            fetchData(search) {
                this.loading = true
                this.search = search

                const params = omitBy(
                    {
                        ...this.params,
                        ...(this.search && { search: this.search }),
                        page: this.pagination.current_page,
                        includes: ['types.slugs', 'name'],
                        isCancellable: true,
                        ...(!this.hideFilters && {
                            source_type: this.sourceType,
                            creditable_type: this.creditableType,
                            enabled: formatBoolean(this.enabledOnly),
                            with_balance: this.withBalance,
                            with_trashed: formatBoolean(this.trashedOnly),
                        }),
                    },
                    isNil
                )

                this.$api.wallets
                    .get(params)
                    .then((response) => {
                        this.wallets = response.data
                        this.pagination = response.meta.pagination
                    })
                    .catch((error) => {
                        if (error && error.response) Alerts.notificationError(error.response.data.message)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            filterByCreditableType(value) {
                this.creditableType = this.creditableType === value ? null : value
                this.fetchData(this.search)
            },
            filterBySourceType(value) {
                this.sourceType = this.sourceType === value ? null : value
                this.fetchData(this.search)
            },
            filterByBalances(value) {
                this.withBalance = this.withBalance === value ? null : value
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            allIds() {
                return this.$options.filters.pluck(this.wallets, 'id')
            },
            open(wallet) {
                if (wallet.deleted_at) return
                let panel = this.openPanel('WalletPanel', wallet.id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData(this.search)
                    }
                })
            },
            toggleFilterEnabled() {
                this.enabledOnly = toggleBoolean(this.enabledOnly)
                this.fetchData(this.search)
            },
            toggleFilterTrashed() {
                this.trashedOnly = toggleBoolean(this.trashedOnly)
                this.fetchData(this.search)
            },
            bulkDeposit(data) {
                this.closeModal('deposit')

                this.bulkAction('Deposit', (id) => {
                    return this.$api.wallets.deposit(id, data)
                }).then(() => {
                    this.fetchData(this.search)
                })
            },
            bulkWithdraw(data) {
                this.closeModal('withdraw')
                this.bulkAction('Withdraw', (id) => {
                    return this.$api.wallets.withdraw(id, data)
                }).then(() => {
                    this.fetchData(this.search)
                })
            },
            bulkSpendingLimit(data) {
                this.closeModal('spendingLimit')
                this.bulkAction('Add spending limit', (id) => {
                    return this.$api.wallets.setSpendingLimits(id, data)
                }).then(() => {
                    this.fetchData(this.search)
                })
            },
            bulkEnable() {
                this.bulkAction('Enable wallets', (id) => {
                    return this.$api.wallets.enable(id)
                }).then(() => {
                    this.fetchData(this.search)
                })
            },
            bulkDisable() {
                this.bulkAction('Disable wallets', (id) => {
                    return this.$api.wallets.disable(id)
                }).then(() => {
                    this.fetchData(this.search)
                })
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>

<style type="text/css" scoped>
    .dt-wallet-icons {
        position: relative;
        z-index: 1;
    }
</style>
