<template>
    <FormRoot>
        <Field
            :form.sync="form"
            name="amount"
            placeholder="Amount in €"
            hint="Enter a positive amount in euros"
        ></Field>
        <Field
            :form.sync="form"
            type="checkbox"
            name="forced"
            hint="You are responsible for any potential impact on third-party systems when enabling this option."
            placeholder="Do you want to force the withdrawal?"
        ></Field>
        <FormActions>
            <Button
                v-if="form.forced"
                is="confirm"
                icon="check"
                @confirm="onSubmit"
                title="Are you sure you want to force the withdrawal?"
                :disabled="!submittable"
            >
                Forcing withdrawal
            </Button>
            <Submit v-else class="float-right" :disabled="!submittable" @click="onSubmit">Withdraw</Submit>
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit, Button } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        mixins: [Forms],
        components: {
            Button,
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        props: {
            wallet: {
                type: Number,
                required: false,
            },
        },
        data() {
            return {
                fields: {
                    amount: null,
                    forced: false,
                },
                messageSuccess: 'The amount has been withdrawn from wallet',
            }
        },
        computed: {
            submittable() {
                return this.form.amount != null && this.form.amount != 0 && this.form.submittable
            },
        },
        methods: {
            submit() {
                return this.$api.wallets.withdraw(this.wallet, this.$data.form.data())
            },
        },
    }
</script>
