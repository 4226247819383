<template>
    <Datatable
        name="Group rules"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
        :hover="false"
        @search="fetchData"
        searchClass="d-none"
    >
        <template v-slot:thead>
            <tr>
                <th>Type</th>
                <th>Rule</th>
            </tr>
        </template>
        <tr v-for="(rule, index) in defaultRules" valign="middle" :key="'rule-' + index">
            <template>
                <td>
                    <div class="d-flex align-items-center">
                        <IconWallet :category="rule.type.technical_name" />
                        <span class="ms-2">{{ rule.type.name.en }}</span>
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-start flex-column">
                        <div
                            class="d-flex align-items-center justify-content-between w-100"
                            v-for="(typeRule, name, tIndex) in settingsRules"
                            :key="'typeRule-' + tIndex"
                            v-if="typeRule.canUses.includes(rule.type.technical_name)"
                        >
                            <!-- Subscription percentage for an allowance -->
                            <div
                                v-if="name === 'allowance_subscription_percentage'"
                                class="d-flex justify-content-between align-items-center w-100"
                            >
                                <Checkbox
                                    :class="['mb-0', { 'pe-none opacity-50': !sendData }]"
                                    :value="!!rule.rules[name]"
                                    @input="
                                        rule.rules[name] = !rule.rules[name]
                                            ? true
                                            : updateRuleValue(false, index, name, messages.percentage)
                                    "
                                    small
                                    >{{ typeRule.name }}</Checkbox
                                >
                                <div
                                    class="d-flex align-items-center"
                                    :class="{ 'pe-none opacity-50': !rule.rules[name] }"
                                >
                                    <Input
                                        v-model="subscriptionPercentageBuffer"
                                        class="input-fields"
                                        type="number"
                                        min="50"
                                        max="100"
                                        name="allowance_subscription_percentage"
                                        placeholder="0"
                                        @input="handleSubscriptionPercentage($event, index, name, messages.percentage)"
                                        :hasError="hasSubscriptionPercentageError"
                                    />
                                    <span class="input-fields-symbol d-inline-block text-center ms-1">%</span>
                                </div>
                            </div>
                            <!-- Maximum authorization amount for Mobility -->
                            <div
                                v-else-if="name === 'maximum_authorization_amount'"
                                class="d-flex justify-content-between align-items-center w-100"
                            >
                                <Checkbox
                                    :class="['mb-0', { 'pe-none opacity-50': !sendData }]"
                                    :value="!!rule.rules[name]"
                                    @input="
                                        rule.rules[name] = !rule.rules[name]
                                            ? true
                                            : updateRuleValue(null, index, name, messages.default)
                                    "
                                    small
                                    >{{ typeRule.name }}</Checkbox
                                >
                                <div
                                    class="d-flex align-items-center"
                                    :class="{ 'pe-none opacity-50': !rule.rules[name] }"
                                >
                                    <Input
                                        v-model="maximumAuthorizationAmountBuffer"
                                        class="input-fields"
                                        type="number"
                                        min="0"
                                        max="1000"
                                        name="maximum_authorization_amount"
                                        placeholder="0"
                                        @input="handleMaximumAuthorizationAmount($event, index, name)"
                                        :hasError="hasMaximumAuthorizationAmountError"
                                    />
                                    <span class="input-fields-symbol d-inline-block text-center ms-1">&euro;</span>
                                </div>
                            </div>
                            <!-- Default -->
                            <Checkbox
                                v-else
                                :value="rule.rules[name]"
                                @input="updateRuleValue(!rule.rules[name], index, name, messages.default)"
                                :class="{ 'pe-none opacity-50': !sendData }"
                                small
                                >{{ typeRule.name }}</Checkbox
                            >
                        </div>
                    </div>
                </td>
            </template>
        </tr>
    </Datatable>
</template>

<script>
    import { Alerts, Checkbox, Datatable, Dropdown, DropdownItem, Input } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import IconWallet from '@/components/IconWallet'
    import RawOutput from '@/components/RawOutput'
    export default {
        name: 'Rules',
        components: {
            Alerts,
            Checkbox,
            Datatable,
            Dropdown,
            DropdownItem,
            IconWallet,
            Input,
            RawOutput,
        },
        props: {
            group: {
                type: Number,
                required: true,
            },
            hideActions: Boolean,
            params: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                defaultRules: [
                    {
                        rules: {
                            auto_extend_deposit: false,
                            allow_authorization_on_days_off: false,
                        },
                        type: {
                            technical_name: 'meal',
                            name: {
                                en: 'Meal',
                            },
                        },
                    },
                    {
                        rules: {
                            bike_mileage_allowance: false,
                            carpooling_allowance: false,
                        },
                        type: {
                            technical_name: 'sustainable_mobility_package',
                            name: {
                                en: 'Sustainable Mobility Package',
                            },
                        },
                    },
                    {
                        rules: {
                            allowance_subscription_percentage: false,
                        },
                        type: {
                            technical_name: 'public_transport_passes',
                            name: {
                                en: 'Public transport passes',
                            },
                        },
                    },
                    {
                        rules: {
                            maximum_authorization_amount: null,
                        },
                        type: {
                            technical_name: 'mobility',
                            name: {
                                en: 'Mobility',
                            },
                        },
                    },
                ],
                maximumAuthorizationAmountBuffer: null,
                subscriptionPercentageBuffer: null,
                settingsRules: {
                    auto_extend_deposit: {
                        name: 'Automatic report of expired credits',
                        canUses: ['meal'],
                    },
                    allow_authorization_on_days_off: {
                        name: 'Allow transactions on Sundays and public holidays',
                        canUses: ['meal'],
                    },
                    bike_mileage_allowance: {
                        name: 'Allowance for bicycle kilometric expenses',
                        canUses: ['sustainable_mobility_package'],
                    },
                    allowance_subscription_percentage: {
                        name: 'Allowance for public transport passes (50-100%)',
                        canUses: ['public_transport_passes'],
                    },
                    carpooling_allowance: {
                        name: 'Allowance for carpooling kilometric expenses',
                        canUses: ['sustainable_mobility_package'],
                    },
                    maximum_authorization_amount: {
                        name: 'Maximum authorization amount',
                        canUses: ['mobility'],
                    },
                },
                pagination: { current_page: 1 },
                messages: {
                    default: 'The value has been changed',
                    percentage: 'The percentage has been changed',
                },
                loading: true,
                sendData: true,
                updateTimers: [],
            }
        },
        computed: {
            hasMaximumAuthorizationAmountError() {
                return (
                    this.maximumAuthorizationAmountBuffer !== null &&
                    (this.maximumAuthorizationAmountBuffer > 1000 || this.maximumAuthorizationAmountBuffer < 0)
                )
            },
            hasSubscriptionPercentageError() {
                return (
                    this.subscriptionPercentageBuffer !== null &&
                    (this.subscriptionPercentageBuffer > 100 || this.subscriptionPercentageBuffer < 50)
                )
            },
        },
        methods: {
            fetchData() {
                if (!this.group) return
                this.loading = true
                this.$api.groups
                    .getRules(
                        this.group,
                        this.mergeParams({
                            page: this.pagination.current_page,
                        })
                    )
                    .then((response) => {
                        this.parseRules(response.data)
                        this.pagination = response.meta.pagination
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response
                                ? error.response.data.message
                                : 'An error has occurred while searching for rules'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            handleSubscriptionPercentage(percentage, index, name, successMessage) {
                clearInterval(this.updateTimers[index])
                this.updateTimers[index] = setInterval(() => {
                    this.updateSubscriptionPercentage(percentage, index, name, successMessage)
                }, 750)
            },
            updateSubscriptionPercentage(percentage, index, name, successMessage) {
                const percentageBuffer = Number(percentage)
                if (percentageBuffer === 0) {
                    this.updateRuleValue(false, index, name, successMessage)
                } else if (percentageBuffer >= 50 && percentageBuffer <= 100) {
                    this.updateRuleValue(percentageBuffer, index, name, successMessage)
                }
                clearInterval(this.updateTimers[index])
            },
            handleMaximumAuthorizationAmount(value, index, name) {
                clearInterval(this.updateTimers[index])
                this.updateTimers[index] = setInterval(() => {
                    this.updateMaximumAuthorizationAmount(value, index, name)
                }, 750)
            },
            updateMaximumAuthorizationAmount(value, index, name) {
                const successMessage = this.messages.default
                const valueBuffer = Number(value)
                if (valueBuffer === 0) {
                    this.updateRuleValue(null, index, name, successMessage)
                } else if (valueBuffer > 0 && valueBuffer <= 1000) {
                    this.updateRuleValue(valueBuffer, index, name, successMessage)
                }
                clearInterval(this.updateTimers[index])
            },
            postData(typeName, ruleName, value, alertSuccess) {
                let params = {
                    rules: {
                        auto_extend_deposit: ruleName === 'auto_extend_deposit' ? value : undefined,
                        allow_authorization_on_days_off:
                            ruleName === 'allow_authorization_on_days_off' ? value : undefined,
                        bike_mileage_allowance: ruleName === 'bike_mileage_allowance' ? value : undefined,
                        allowance_subscription_percentage:
                            ruleName === 'allowance_subscription_percentage' ? value : undefined,
                        ...(ruleName === 'carpooling_allowance' && { carpooling_allowance: value }),
                        ...(ruleName === 'maximum_authorization_amount' && { maximum_authorization_amount: value }),
                    },
                    technical_name: typeName ? typeName : undefined,
                }
                params = { ...this.params, ...params }
                this.$api.groups
                    .createRule(this.group, params)
                    .then(() => {
                        Alerts.notificationSuccess(alertSuccess ? alertSuccess : 'Group rules updated')
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response
                                ? error.response.data.message
                                : 'An error has occurred while saving rules'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.sendData = true
                    })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            parseRules(rules) {
                if (rules.length > 0) {
                    rules.forEach((rule, index) => {
                        let defaultRulesIndex = this.defaultRules.findIndex(
                            (drules) => drules.type.technical_name === rule.type.technical_name
                        )
                        if (defaultRulesIndex != -1) this.defaultRules[defaultRulesIndex].rules = rule.rules
                        if (index === rules.length - 1) this.loading = false
                    })
                }
            },
            updateRuleValue(value, index, name, successMessage) {
                if (this.sendData) {
                    if (this.defaultRules[index].rules[name] !== value) {
                        this.sendData = false
                        this.defaultRules[index].rules[name] = value
                        this.postData(this.defaultRules[index].type.technical_name, name, value, successMessage)
                    }
                }
            },
            toggleBlockDays(index, name, successMessage) {
                if (!this.defaultRules[index].rules[name]) {
                    this.defaultRules[index].rules[name] = []
                } else {
                    this.updateRuleValue(false, index, name, successMessage)
                }
            },
        },
        mounted() {
            this.fetchData()
        },
        watch: {
            defaultRules: {
                deep: true,
                handler(rules) {
                    const publicRule = rules.find((rule) => rule.type.technical_name === 'public_transport_passes')
                    const mobilityRule = rules.find((rule) => rule.type.technical_name === 'mobility')
                    this.subscriptionPercentageBuffer =
                        publicRule &&
                        publicRule.rules &&
                        typeof publicRule.rules.allowance_subscription_percentage !== 'boolean'
                            ? publicRule.rules.allowance_subscription_percentage
                            : null
                    this.maximumAuthorizationAmountBuffer =
                        mobilityRule && mobilityRule.rules && mobilityRule.rules.maximum_authorization_amount
                            ? mobilityRule.rules.maximum_authorization_amount
                            : null
                },
            },
        },
    }
</script>

<style type="text/css" scoped>
    .input-fields {
        width: 160px;
    }
    .input-fields-symbol {
        min-width: 24px;
    }
</style>
